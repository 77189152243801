import React from 'react';
import { IShiftProfitByAccounts } from 'interfaces/financial-stats/IFinancialStats.ts';
import { Column } from '@ant-design/charts';
import { ILocale } from 'interfaces/locale.ts';

type Props = {
  shift_profit_by_accounts?: IShiftProfitByAccounts[];
  locale: ILocale
};

export const ShiftInOutcomeByAccountsColumnChart = ({ shift_profit_by_accounts, locale }: Props): JSX.Element => {

  const sortedData: IShiftProfitByAccounts[] = (shift_profit_by_accounts || [])
    .sort((a: IShiftProfitByAccounts, b: IShiftProfitByAccounts): number => {
      if (a.type === 'income' && b.type !== 'income') {
        return -1;
      }
      if (a.type !== 'income' && b.type === 'income') {
        return 1;
      }
      return 0;
    });

  const colorRange = (): string[] => {
    const hasIncome = shift_profit_by_accounts
      ?.some((item: IShiftProfitByAccounts): boolean => item.type === 'income');
    const hasOutcome = shift_profit_by_accounts
      ?.some((item: IShiftProfitByAccounts): boolean => item.type === 'outcome');

    if (hasIncome && hasOutcome) {
      return ['#8ccc90', '#d66145'];
    } else if (hasIncome) {
      return ['#8ccc90'];
    } else if (hasOutcome) {
      return ['#d66145'];
    }
    return [];
  };

  return (
    <Column
      title={locale.labels.incomeAndOutcome}
      data={sortedData}
      isStack={false}
      xField='account_name'
      yField='amount'
      seriesField='payment_type'
      colorField='payment_type'
      style={{
        maxWidth: 50,
        minWidth: 30
      }}
      scale={{ color: { range: colorRange() } }}
      label={{
        text: 'label',
        textBaseline: 'bottom',
        style: {
          fill: '#000',
          fontSize: 14,
          fontWeight: 'bold',
          textAlign: 'center'
        },
        offset: 10
      }}
    />
  );
};
