import 'components/scss/HealthRecordsHistory.scss';
import 'moment/locale/uk';
import '../scss/StockDetails.scss';

import { Col, Row, Spin, Table, TableColumnProps } from 'antd';
import ListFieldWithTooltip from 'components/ListFieldWithTooltip';
import { TextEditorView } from 'components/TextEditorView';
import useClinicId from 'hooks/useClinicId';
import { useI18n } from 'hooks/usei18n.ts';
import { useLocale } from 'hooks/useLocale';
import { measurementsLocale } from 'i18n/measurements.ts';
import { packagesLocale } from 'i18n/packages.ts';
import { IAppState } from 'interfaces/app-state';
import { ILocale } from 'interfaces/locale';
import moment from 'moment';
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { getStockItemsById, setSelectedStockItems } from 'redux/inventory/stock/actions';
import {
  IInvoiceStockItem,
} from 'services/clinic/inventory/invoices/inventoryItems/models/showInventoryItemResponse.ts';
import { ISelectedStock } from 'services/interfaces/clinics/inventory/stock/ISelectedStock';
import { ISelectedStockItem } from 'services/interfaces/clinics/inventory/stock/ISelectedStockItem';

import getExpirationStatus from '../helpers/ExpirationTagSelector';
import { useGetResidual } from '../utils/useGetResidual';

type Props = {
  locale: ILocale;
  stockId: number;
  selectedStock: ISelectedStock;
};

export const StockHistoryTab = ({ locale, stockId, selectedStock }: Props): JSX.Element => {
  const clinicId = useClinicId();
  const dispatch = useDispatch();
  const currencies = useLocale('private.currencies').labels;
  const packages = useI18n(packagesLocale);
  const measurements = useI18n(measurementsLocale);

  const selectedStockItems = useSelector(({ stock }: IAppState) => stock.selectedStockItems);
  const loading = useSelector(({ stock }: IAppState) => stock.stockItemsLoading);

  const { unitOfMeasurement, packagingFormat } = useGetResidual(selectedStock);

  useEffect(() => {
    dispatch(getStockItemsById(clinicId, stockId, 0));

    return () => {
      dispatch(setSelectedStockItems(null));
    };
  }, []);

  const getExpirationDate = (e: ISelectedStockItem): JSX.Element | '-' => {
    return e.expiration_date ? getExpirationStatus(e.expiration_date) : '-';
  };

  const itemFormat = (item: IInvoiceStockItem): string => {
    const packagingFormat = packages[item?.purchase_format] || '';
    const unitOfMeasurement =
      measurements[item?.purchase_format] || '';
    return packagingFormat || unitOfMeasurement;
  };

  const getPrice = (price: number, unitSellingPrice?: number) => {
    return (
      <Col>
        {selectedStock?.packaging_format !== 'none' && (
          <Row className='time-cell-row' style={{ width: 'max-content' }}>
            {`${packagingFormat}: ${price} ${currencies.uah}`}
          </Row>
        )}
        <Row className='time-cell-row' style={{ width: 'max-content' }}>
          {selectedStock?.unit_of_measurement !== 'pcs' ||
          selectedStock?.packaging_format !== 'none'
            ? unitOfMeasurement + ': '
            : ''}
          {unitSellingPrice ?? (price / selectedStock?.quantity_per_package).toFixed(2)} {currencies.uah}
        </Row>
      </Col>
    );
  };

  const columns: TableColumnProps<ISelectedStockItem>[] = [
    {
      key: 'invoice_number',
      title: locale.labels.invoiceNumber,
      render: (e) =>
        e.invoice_id
          ? ListFieldWithTooltip(
              <Link
                className={'custom-links'}
                to={`/clinic/${clinicId}/inventory/invoices/${e.invoice_id}`}
              >
                {e.invoice_number}
              </Link>
            )
          : '-'
    },
    {
      key: 'purchase_date',
      title: locale.labels.purchaseDate,
      render: (e) => `${e.purchase_date ? moment(e.purchase_date).format('DD.MM.YYYY') : '-'}`
    },
    {
      key: 'series',
      title: locale.labels.series,
      render: (e) => e.series || '-'
    },
    {
      key: 'expiration_date',
      title: locale.labels.expirationDate,
      render: getExpirationDate
    },
    {
      key: 'purchase_price',
      title: locale.labels.pricePerUnit,
      render: (e) => getPrice(e?.price_per_unit)
    },
    {
      key: 'purchase_format',
      title: locale.labels.purchaseFormat,
      render: (e) => itemFormat(e) || '-'
    },
    {
      key: 'initial_quantity',
      title: locale.labels.purchaseQuantity,
      render: (e) => e.initial_quantity || '-'
    },
    {
      key: 'selling_price',
      title: locale.labels.price,
      render: (e) => getPrice(e?.selling_price, e?.unit_selling_price)
    },
    Table.EXPAND_COLUMN
  ];

  return (
    <div className='stock-row-container__table'>
      <Spin spinning={loading}>
        {Boolean(selectedStockItems?.data) && (
          <Table
            rowClassName={(record) => (record.deleted ? 'disabled-row' : '')}
            pagination={false}
            className='accent-header stock-list'
            rowKey='id'
            dataSource={selectedStockItems.data.reverse()}
            columns={columns}
            expandable={{
              rowExpandable: (record) => !!record.notes,
              expandedRowRender: (record) => (
                <Table
                  rowKey='id'
                  dataSource={[record]}
                  columns={[
                    {
                      key: 'notes',
                      title: 'Notes',
                      render: () => <TextEditorView value={record.notes} borderless />
                    }
                  ]}
                  pagination={false}
                  size='small'
                  className='invoice-items-table'
                  bordered
                />
              )
            }}
          />
        )}
      </Spin>
    </div>
  );
};
