import React from 'react';
import { IBilledOverPaid } from 'interfaces/financial-stats/IFinancialStats.ts';
import { Column } from '@ant-design/charts';
import { ILocale } from 'interfaces/locale.ts';

type Props = {
  billed_over_paid?: IBilledOverPaid[];
  locale: ILocale
};

export const BilledOverPaidColumnChart = ({ billed_over_paid, locale }: Props): JSX.Element => {

  return (
    <Column
      title={locale.labels.billedOverPaid}
      data={billed_over_paid || []}
      isStack={false}
      xField='name'
      yField='amount'
      colorField='name'
      style={{
        maxWidth: 50,
        minWidth: 30
      }}
      label={{
        text: 'label',
        textBaseline: 'bottom',
        style: {
          fill: '#000',
          fontSize: 14,
          fontWeight: 'bold',
          textAlign: 'center'
        },
        offset: 10
      }}
    />
  );
};
