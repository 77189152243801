import catBreeds from './breeds/cat';
import dogBreeds from './breeds/dog';
import rodentBreeds from './breeds/rodent';
import birdBreeds from './breeds/bird';
import exoticBreeds from './breeds/exotic';
import catColors from './colors/cat';
import dogColors from './colors/dog';
import rodentColors from './colors/rodent';
import catCoats from './coats/cat';
import dogCoats from './coats/dog';
import rodentCoats from './coats/rodent';

const animals = [
  {
    enGB: 'Cat',
    ukUA: 'Кіт',
    value: 'cat',
    colors: catColors,
    breeds: catBreeds,
    coats: catCoats
  },
  {
    enGB: 'Dog',
    ukUA: 'Собака',
    value: 'dog',
    colors: dogColors,
    breeds: dogBreeds,
    coats: dogCoats
  },
  {
    enGB: 'Rodent',
    ukUA: 'Гризун',
    value: 'rodent',
    colors: rodentColors,
    breeds: rodentBreeds,
    coats: rodentCoats
  },
  {
    enGB: 'Bird',
    ukUA: 'Птах',
    value: 'bird',
    breeds: birdBreeds,
    colors: [],
    coats: []
  },
  {
    enGB: 'Exotic',
    ukUA: 'Екзотика',
    value: 'exotic',
    breeds: exoticBreeds,
    colors: [],
    coats: []
  },
  {
    enGB: 'Other',
    ukUA: 'Інший',
    value: 'other',
    breeds: [],
    colors: [],
    coats: []
  }
];

export default animals;
