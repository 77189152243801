import { Table, TableColumnProps } from 'antd';
import dayjs from 'dayjs';
import { money } from 'helpers/list-helpers';
import { AddBillPayment } from 'layout/modals/finances/addBillPayment/AddBillPayment';
import React from 'react';
import { useNavigate } from 'react-router-dom';

const BillsDetailsPayments = ({ data, locale, currencies, bill, clinicId, openedShift }: any) => {
  const navigate = useNavigate();

  const redirectToPaymentDetails = (record: any) => {
    return {
      onClick: () => {
        navigate(`/clinic/${clinicId}/finances/payments/${record.id}`);
      }
    };
  };

  const columns: Array<TableColumnProps<any>> = [
    {
      key: 'created_at',
      dataIndex: 'created_at',
      title: locale.labels.date,
      sorter: (a, b) => (a.created_at > b.created_at ? 1 : -1),
      render: (created_at, e) =>
        created_at !== undefined ? dayjs(created_at).format('DD.MM.YYYY HH:mm') : '-',
      onCell: redirectToPaymentDetails
    },
    {
      key: 'amount',
      dataIndex: 'amount',
      title: locale.labels.amount,
      sorter: (a, b) => (a.amount > b.amount ? 1 : -1),
      render: (amount, e) => (amount ? `${money(amount)} ${currencies.uah}` : '-'),
      onCell: redirectToPaymentDetails
    },
    {
      key: 'financial_account.name',
      dataIndex: 'accountName',
      title: locale.labels.accountName,
      sorter: (a, b) => (a?.financial_account?.name > b?.financial_account?.name ? 1 : -1),
      render: (notes, e) => e?.financial_account?.name || '-',
      onCell: redirectToPaymentDetails
    },
    {
      key: 'financial_account.account_number',
      title: locale.labels.accountNumber,
      sorter: (a, b) =>
        a?.financial_account?.account_number > b?.financial_account?.account_number ? 1 : -1,
      render: (e) => e?.financial_account?.account_number || '-',
      onCell: redirectToPaymentDetails
    }
  ];

  return (
    <>
      <h4>{locale.labels.payments}</h4>
      <Table
        pagination={false}
        className='accent-header bills-payments-list-table'
        rowKey='id'
        dataSource={data}
        columns={columns}
        rowClassName={(record) => (record.deleted ? 'disabled-row' : '')}
      />
      <div style={{ padding: 10 }}>
        {(bill?.payment_status !== 'paid' && !bill?.deleted) && (
          <AddBillPayment bill={bill} openedShift={openedShift} />
        )}
      </div>
    </>
  );
};

export default BillsDetailsPayments;
