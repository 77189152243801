import { Menu } from 'antd';
import * as config from 'config/config-manager.ts';
import useClinicId from 'hooks/useClinicId';
import { useLocale } from 'hooks/useLocale';
import { useUserAccess } from 'hooks/useUserAccess';
import React, { useEffect, useState } from 'react';
import { NavLink, Outlet, useLocation } from 'react-router-dom';

import { PremiumSubscriptionTooltip } from '../../components/PremiumSubscriptionTooltip';

const ClinicActions = () => {
  const userAccess = useUserAccess();
  const scopes = userAccess.accesses;
  const modules = userAccess.availableModules;
  const clinicId = useClinicId();
  const params = useLocation().pathname.split('/')[4];
  const [current, setCurrent] = useState(params || '/');
  const locale = useLocale('private.administration.tabs');

  useEffect(() => {
    setCurrent(params?.length ? params : '/');
  }, [params]);

  const tabs = [
    {
      name: 'info',
      label: locale.tabs.general,
      permission: true
    },
    {
      name: 'employees',
      label: locale.tabs.employees,
      permission: scopes.employees.showList
    },
    {
      name: 'invitations',
      label: locale.tabs.invitations,
      permission: !config.disabledForProd && scopes.invitations.showList
    },
    {
      name: 'rooms',
      label: locale.tabs.rooms,
      permission: scopes.rooms.showList
    },
    {
      name: 'roles',
      label: locale.tabs.roles,
      permission: !config.disabledForProd && scopes.roles.showList,
      disabled: !modules.roles
    },
    {
      name: 'documents',
      label: locale.tabs.documents,
      permission: true,
      disabled: !modules.documents
    },
    {
      name: 'dictionaries',
      label: locale.tabs.dictionaries,
      permission: true
    },
    {
      name: 'print',
      label: locale.tabs.print,
      permission: true
    }
  ];

  const generateOptions = () =>
    tabs.map((item) => {
      if (!item.permission) return null;
      return {
        key: item.name,
        disabled: item.disabled,
        label: (
          <PremiumSubscriptionTooltip display={item.disabled}>
            <NavLink
              onClick={(event) => {
                if (item.disabled) event.preventDefault();
              }}
              to={`/clinic/${clinicId}/administration/${item.name}`}
            >
              {item.label}
            </NavLink>
          </PremiumSubscriptionTooltip>
        )
      };
    });

  return (
    <>
      <Menu
        selectedKeys={[current]}
        mode='horizontal'
        items={generateOptions()}
        style={{ marginBottom: '10px' }}
      />
      <Outlet />
    </>
  );
};

export default ClinicActions;
