import { Form, Input, Select } from 'antd';
import { allCountries } from 'country-telephone-data';
import { useLocale } from 'hooks/useLocale';
import React from 'react';
import { patternRule, PATTERNS, requiredRule } from 'utils/form-rules';

type PrefixProps = {
  prefix?: (string | number)[];
  countryCode?: string;
};

type PhoneFieldProps = {
  formItemPhonePrefix?: (string | number)[];
  formItemPhoneNumber?: (string | number)[];
  initialValues?: {
    phone_number: string;
    country_code: string;
  };
  hideLabel?: boolean;
};

const countryOptions = allCountries
  .map((item, index) => ({
    label: item.name,
    value: item.dialCode,
    key: index
  }))
  .sort((item) => (item.value === '380' ? -1 : 1));

const PrefixSelector = ({ prefix, countryCode }: PrefixProps): JSX.Element => (
  <Form.Item name={prefix || 'country_code'} initialValue={countryCode || '380'} noStyle>
    <Select
      style={{ width: 80 }}
      showSearch
      optionFilterProp='children'
      filterOption={(input, option) =>
        (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
      }
      options={countryOptions}
      optionLabelProp='value'
      popupClassName='custom-countries-list-options'
    ></Select>
  </Form.Item>
);

const PhoneFieldFormItem = ({
  formItemPhonePrefix,
  formItemPhoneNumber,
  initialValues,
  hideLabel
}: PhoneFieldProps): JSX.Element => {
  const locale = useLocale('private.user.user-profile');

  return (
    <Form.Item
      label={!hideLabel && locale.labels.phoneNumber}
      initialValue={initialValues?.phone_number || null}
      name={formItemPhoneNumber || 'phone_number'}
      rules={[
        requiredRule(locale.messages.phoneNumberNotValid),
        patternRule(PATTERNS.PHONE, locale.messages.phoneNumberNotValid)
      ]}
    >
      <Input
        className={'input-group-custom'}
        addonBefore={
          <PrefixSelector prefix={formItemPhonePrefix} countryCode={initialValues?.country_code} />
        }
        style={{ width: '100%' }}
        placeholder='(__)-___-____'
      />
    </Form.Item>
  );
};

export default PhoneFieldFormItem;
