import React from 'react';
import { IEmployeesProfit } from 'interfaces/financial-stats/IFinancialStats.ts';
import { Column } from '@ant-design/charts';
import { ILocale } from 'interfaces/locale.ts';

type Props = {
  employees_profit?: IEmployeesProfit[];
  locale: ILocale
};

export const ShiftBilledAmountByEmployeeColumnChart = ({ employees_profit, locale }: Props): JSX.Element => {

  return (
    <Column
      title={locale.labels.billedByEmployees}
      data={employees_profit || []}
      isStack={false}
      xField='user_name'
      yField='amount'
      colorField='type'
      seriesField='type'
      style={{
        maxWidth: 50,
        minWidth: 30
      }}
      label={{
        text: 'label',
        textBaseline: 'bottom',
        style: {
          fill: '#000',
          fontSize: 14,
          fontWeight: 'bold',
          textAlign: 'center'
        },
        offset: 10
      }}
    />
  );
};
