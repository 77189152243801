import { scrollToTop } from 'helpers/list-helpers';
import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useSearchParams } from 'react-router-dom';

import { ISearchCriteria } from '../interfaces/search-criteria.ts';

interface Props {
  action: any,
  clinicId?: number,
  defaultSortKey: string,
  defaultSortOrder: string,
  customFilters?: ISearchCriteria[] | any,
  additionalParam?: number,
  defaultSearchParams?: boolean,
  selectOptions?: any,
  customSearchParam?: any
}

export const useSearch =({ action, clinicId, defaultSortKey, defaultSortOrder, customFilters = [],
  additionalParam, defaultSearchParams, selectOptions, customSearchParam }: Props) => {
  const dispatch = useDispatch();

  const [searchParams, setSearchParams] = useSearchParams();

  const currentPage = searchParams.get('page');
  const sort_key = searchParams.get('sort_key');
  const sort_order = searchParams.get('sort_order');
  const filter = searchParams.get('filter');
  const startDateFrom = searchParams.get('startDateFrom');
  const startDateTo = searchParams.get('startDateTo');
  const statuses = searchParams.get('statuses');
  const listRange = searchParams.get('list_range');

  useEffect(() => {
    if (defaultSearchParams) {
      setParams(currentPage ?? '1', sort_key ?? defaultSortKey, sort_order ?? defaultSortOrder,
      filter ?? '', startDateFrom ?? '', startDateTo ?? '', statuses ?? '');
    }
  }, []);

  useEffect(() => {
    return () => setSearchParams({});
  }, []);

  useEffect((): void => {
    if (searchParams.get('page')) getResults();
  }, [searchParams]);

  const onPageChange = (page: number): void => {
    scrollToTop();
    setParams(page, sort_key, sort_order, filter, startDateFrom, startDateTo, statuses);
  };

  const handleChange = (pagination, filters, sorter): void => {
    let order = '';
    let sortKey = sorter.columnKey;
    if (sorter.order) {
      order = sorter.order === 'ascend' ? 'asc' : 'desc';
    } else {
      order = defaultSortOrder;
      sortKey = defaultSortKey;
    }
    setParams(currentPage, sortKey, order, filter, startDateFrom, startDateTo, statuses);
  };

  const setParams = (page: number | string, sort_key: string, sort_order: string,
    filter: string, startDateFrom: string, startDateTo: string, statuses: any) => {

    let newParams = {
      page: String(page),
      sort_key,
      sort_order,
      filter,
      startDateFrom,
      startDateTo,
      statuses: Array.isArray(statuses) ? statuses.join(',') : statuses
    };
    if (customSearchParam) {
      newParams = {...newParams, ...customSearchParam};
    }

    setSearchParams(newParams);
  };

  const filterResults = (data) => {
      setParams(currentPage ?? '1', sort_key ?? defaultSortKey, sort_order ?? defaultSortOrder, data.filter ?? '',
       data.startDateFrom ?? '', data.startDateTo ?? '', data.statuses ?? '');
  };

  const checkCriteriaFilter = (val: string): string => {
    const res = selectOptions.find((el) => el.value === val);
    return res?.filter ?? '';
  };

  const getResults = () => {
    const searchArray = [];
    if (filter !== null) {
      const criteria = filter.split(' ');
      criteria.filter(c => c !== '')
        .forEach((c) => {
        if (c && c !== '') {
          searchArray.push({
            name: 'composite',
            value: c,
            isArrayValue: true
          });
        }
      });
    }

    if (startDateFrom) {
      searchArray.push({
        name: 'start_time_from',
        value: startDateFrom
      });
    }

    if (startDateTo) {
      searchArray.push({
        name: 'start_time_to',
        value: startDateTo
      });
    }

    if (listRange) {
      searchArray.push({
        name: 'list_range',
        value: listRange
      });
    }

    if (statuses) {
      const statusesList = statuses.split(',');
      statusesList?.forEach((criteria) => {
        if (criteria) {
          searchArray.push({
            name: selectOptions ? checkCriteriaFilter(criteria) : 'status',
            value: criteria,
            isArrayValue: true
          });
        }
      });
    }
      searchArray.push(...customFilters);
    if (!clinicId) {
       dispatch(action(+currentPage, searchArray, {sort_key, sort_order}));
    } else if (additionalParam) {
      dispatch(action(clinicId, additionalParam, +currentPage, searchArray, {sort_key, sort_order}));
    } else {
      dispatch(action(clinicId, +currentPage, searchArray, {sort_key, sort_order}));
    }
  };

  return { handleChange, onPageChange, getResults, filterResults };
};
