import './scss/PremiumSubscriptionTooltip.scss';

import { Tooltip } from 'antd';
import { useLocale } from 'hooks/useLocale';
import React from 'react';

type Props = {
  display: boolean;
  children: React.ReactNode;
};

export const PremiumSubscriptionTooltip = ({ display, children }: Props): JSX.Element => {
  const locale = useLocale('private.subscription-tooltip');

  return (
    <Tooltip
      trigger={'hover'}
      placement='topLeft'
      title={display ? locale.messages.availableInPremium : null}
    >
      <div style={{ display: 'flex' }}>
        <span className={display ? 'locked-label' : undefined}>{children}</span>
        {display && <div className='icofont icofont-lock locked-tag'></div>}
      </div>
    </Tooltip>
  );
};
