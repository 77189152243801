import React from 'react';
import { Tag } from 'antd';
import { allStatuses } from 'constants/dictionary/default/localizedStatuses';
import { useSelector } from 'react-redux';
import { AppState } from '../redux/store.ts';

type Props = {
  status: string;
};

export const StatusTag = ({ status }: Props): JSX.Element => {
  const localeCode = useSelector<AppState, string>(({ localeCode }) => localeCode);
  const localizedStatus = allStatuses[status];
  return (
    <Tag id='payment-status-tag' color={localizedStatus.color}>{localizedStatus[localeCode]}</Tag>
  );
};
