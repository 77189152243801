import { Col, Form, Row, Spin } from 'antd';
import { TextEditorView } from 'components/TextEditorView';
import ViewDetailsButtonsGroup from 'components/ViewDetailsButtonsGroup';
import { billType } from 'constants/dictionary/default/selectOptions';
import dayjs from 'dayjs';
import { useLocale } from 'hooks/useLocale';
import useLocalizedList from 'hooks/useLocalizedList';
import { useUserAccess } from 'hooks/useUserAccess';
import { IAppState } from 'interfaces/app-state';
import EditPayment from 'layout/modals/finances/addPayment/EditPayment';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useParams } from 'react-router-dom';
import { getPaymentsDetails, removePayment, setSelectedPayment, showModal } from 'redux/finances/payments/actions';
import valueFromList from 'utils/value-from-list';

import getType from '../helpers/TagSelector';
import { getPaymentName } from './utils/utils';
import { DeletedInfo } from 'components/EntityDetails/DeletedInfo';
import { StatusTag } from 'components/StatusTag';
import ConfirmDeleteModal from 'components/ConfirmDeleteModal.tsx';

const PaymentsDetails = (): JSX.Element => {
  const dispatch = useDispatch();
  const { clinicId, paymentId } = useParams();
  const userAccess = useUserAccess();
  const locale = useLocale('private.finances.payments');
  const currencies = useLocale('private.currencies').labels;

  const data = useSelector(({ payments }: IAppState) => payments.selectedBill);
  const showPaymentModal = useSelector(({ payments }: IAppState) => payments.showModal);
  const loading = useSelector(({ payments }: IAppState) => payments.loading);
  const [showDeleteModal, setShowDeleteModal] = useState(false);

  const [EditPaymentForm] = Form.useForm();

  useEffect(() => {
    if (clinicId && paymentId) dispatch(getPaymentsDetails(+clinicId, +paymentId));

    return () => {
      dispatch(setSelectedPayment(null));
    };
  }, [clinicId]);

  const localizedBillType = useLocalizedList(billType);

  const getTypeTag = (type) => {
    const getLocalizedLabel = (val: any) => {
      return localizedBillType.find((el: any) => el.value === val);
    };
    return { value: type, label: getLocalizedLabel(type).label };
  };

  const onConfirmDeleteClick = () => {
    dispatch(removePayment(+clinicId, +paymentId,
      () => setShowDeleteModal(false)));
  };

  return (
    <Spin spinning={loading} className='payments-details-page-spin-container'>
      <Col span={24}>
        {!!data && (
          <div className={'name-statuses-form-header'}>
            <h4 style={{ marginRight: '8px' }} id='payment-name'>
              {getPaymentName(data?.name, locale)}
            </h4>
            <span id='payment-type'>{getType(getTypeTag(data?.payment_type))}</span>
            {data?.deleted &&
              <span
                id='status-tag'
                style={{ marginLeft: '5px' }}>
              <StatusTag status={'deleted'} />
            </span>}
          </div>
        )}
      </Col>
      <div style={{ borderBottom: '1px solid rgba(5, 5, 5, 0.06)' }}>
        <div className='appointment-details-top-container' style={{ marginBottom: 10 }}>
          <Row>
            <Col
              span={24}
              md={12}
              className='appointment-details-top-container__left-column'
              style={{ left: 10 }}
            >
              {data?.deleted &&
                <DeletedInfo deleted_at={data?.deleted_at} deleted_by={data?.deleted_by} />}
              <Col>
                {data?.date && (
                  <>
                    <span style={{ fontWeight: 600 }}>{locale.labels.date}: </span>
                    <span id='date-time'>{dayjs(data.date).format('DD.MM.YYYY HH:mm')}</span>
                  </>
                )}
              </Col>
              <Col>
                {data?.amount && (
                  <>
                    <span style={{ fontWeight: 600 }}>{locale.labels.amount}: </span>
                    <span id='amount'>
                      {Number(data?.amount).toFixed(2)} {currencies.uah}
                    </span>
                  </>
                )}
              </Col>
              <Col>
                {data?.bill?.name && (
                  <>
                    <span style={{ fontWeight: 600 }}>{locale.labels.bill}: </span>
                    <Link to={`/clinic/${clinicId}/finances/bills/${data?.bill?.id}`}>
                      <span id='bill-name'>{data?.bill?.id}</span>
                    </Link>
                  </>
                )}
              </Col>
              <Col>
                {data?.payment_type && (
                  <>
                    <span style={{ fontWeight: 600 }}>{locale.labels.paymentType}: </span>
                    <span id='account-name'>
                      {valueFromList(localizedBillType, data?.payment_type)}
                    </span>
                  </>
                )}
              </Col>
            </Col>
            <Col
              span={24}
              md={12}
              className='appointment-details-top-container__right-column'
              style={{ left: 10 }}
            >
              <Col>
                <span style={{ fontWeight: 600 }}>{locale.labels.category}: </span>
                <span id='category'>{data?.category || '-'}</span>
              </Col>
              <Col>
                {data?.financial_account?.account_number && (
                  <>
                    <span style={{ fontWeight: 600 }}>{locale.labels.account}: </span>
                    <Link
                      to={`/clinic/${clinicId}/finances/financial-accounts/${data?.financial_account?.id}`}
                    >
                      <span id='bill-name'>
                        {data?.financial_account?.name || data?.financial_account?.account_number}
                      </span>
                    </Link>
                  </>
                )}
              </Col>
              <Col>
                <span style={{ fontWeight: 600 }}>{locale.labels.description}: </span>
                <TextEditorView value={data?.description} id='description' borderless />
              </Col>
              <Col>
                <span style={{ fontWeight: 600 }}>{locale.labels.notes}: </span>
                <TextEditorView value={data?.notes} id='notes' borderless />
              </Col>
            </Col>
          </Row>
        </div>
      </div>
      {!data?.deleted && <div style={{ visibility: !showPaymentModal ? 'visible' : 'hidden', padding: 10 }}>
        <ViewDetailsButtonsGroup
          hideCancelBtn
          fixed
          hideDeleteBtn={!!data?.account_from_id || !!data?.account_to_id}
          accessGroup={userAccess.accesses.services}
          editMode={false}
          onEditClick={() => dispatch(showModal(true))}
          onDeleteClick={() => setShowDeleteModal(true)}
        />
      </div>}
      {showPaymentModal && (
        <EditPayment form={EditPaymentForm} locale={locale} initialValues={data} />
      )}
      <ConfirmDeleteModal
        type={'device'}
        open={showDeleteModal}
        onOk={onConfirmDeleteClick}
        onCancel={() => setShowDeleteModal(false)}
      />
    </Spin>
  );
};

export default PaymentsDetails;
