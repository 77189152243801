import React from 'react';
import { Spin } from 'antd';

type Props = {
  loading: boolean;
};

export const PageInitialLoader = ({ loading }: Props): JSX.Element => {
  return (
    <>
      {loading &&
        <Spin spinning={loading} size='large'>
          <div style={{ width: '100vw', height: '100vh' }}></div>
        </Spin>
      }
    </>
  );
};
