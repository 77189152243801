import { Form } from 'antd';
import ConfirmCancelModal from 'components/ConfirmCancelModal';
import ViewDetailsButtonsGroup from 'components/ViewDetailsButtonsGroup';
import VLXModal from 'components/VLXModal';
import { useLocale } from 'hooks/useLocale';
import { useUserAccess } from 'hooks/useUserAccess';
import { IAppState } from 'interfaces/app-state';
import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { setShowCreateProviderModal } from 'redux/inventory/providers/actions';
import { ISelectedProvidersItem } from 'services/interfaces/clinics/inventory/providers/ISelectedProvidersItemsData';

import { AddProviderForm } from './AddProviderForm';

interface Props {
  callBackFunction?: (data: ISelectedProvidersItem) => void;
  defaultProviderNameValue?: string;
}

export const AddProvider = ({callBackFunction, defaultProviderNameValue}: Props): JSX.Element => {
  const dispatch = useDispatch();
  const userAccess = useUserAccess();
  const locale = useLocale('private.inventory.providers');
  const [form] = Form.useForm();

  const [showCancelModal, setShowCancelModal] = useState<boolean>(false);
  const { showCreateModal } = useSelector<IAppState, any>((state) => state.providers);

  const onConfirmCancelClick = (): void => {
    form.resetFields();
    dispatch(setShowCreateProviderModal(false));
    setShowCancelModal(false);
  };

  return (
    <>
      <VLXModal
        title={locale.labels.createProvider}
        centered={true}
        form={form}
        open={showCreateModal}
        onModalClose={() => dispatch(setShowCreateProviderModal(false))}
        width={800}
      >
        {showCreateModal && (
          <AddProviderForm form={form} callBackFunction={callBackFunction} defaultProviderNameValue={defaultProviderNameValue}>
            <ViewDetailsButtonsGroup
              fixed
              accessGroup={userAccess.accesses.providers}
              editMode={true}
              hideDeleteBtn={true}
              onCancelClick={() => {
                if (form.isFieldsTouched()) {
                  setShowCancelModal(true);
                } else {
                  dispatch(setShowCreateProviderModal(false));
                }
              }}
              onDeleteClick={() => {
                setShowCancelModal(false);
              }}
            />
          </AddProviderForm>
        )}
        <ConfirmCancelModal
          open={showCancelModal}
          onOk={onConfirmCancelClick}
          onCancel={() => setShowCancelModal(false)}
        />
      </VLXModal>
    </>
  );
};
