import 'assets/scss/row.scss';
import './EmployeeProfile.scss';

import { Col, Form, Input, Row, Select } from 'antd';
import ConfirmCancelModal from 'components/ConfirmCancelModal';
import MultiSkillsSelect from 'components/MultiSkillsSelect';
import ViewDetailsButtonsGroup from 'components/ViewDetailsButtonsGroup';
import * as config from 'config/config-manager';
import { useUserAccess } from 'hooks/useUserAccess';
import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { editEmployee } from 'redux/employees/actions';
import { EditEmployeeRequest } from 'services/interfaces/clinics/employees/edit-employee-request';

const FormItem = Form.Item;
const { TextArea } = Input;

const EmployeeProfileForm = ({
                               employee,
                               clinicId,
                               employeeId,
                               roles,
                               locale
                             }: any): JSX.Element => {
  const [form] = Form.useForm();
  const dispatch = useDispatch();
  const [editMode, setEditMode] = useState(false);
  const userAccess = useUserAccess();

  const location = (): string => {
    const loc = employee.location;
    return loc ? ['country', 'region', 'city', 'address']
      .map((k) => loc[k])
      .filter((value) => value && value !== '')
      .join(', ') : '-';
  };

  useEffect(() => {
    form.resetFields();
  }, [employee]);

  const rolesOptions = () => {
    return roles.map((item: any) => ({
      value: item.id,
      label: item.name
    }));
  };

  function onSaveClick(values) {
    const payload: EditEmployeeRequest = {
      first_name: employee.first_name,
      last_name: employee.last_name,
      birth_date: employee.birth_date,
      country: employee.location.country,
      region: employee.location.region,
      city: employee.location.city,
      address: employee.location.address,
      role_id: form.getFieldValue('role_id') || employee.role.id,
      skills: selectedItems,
      title: values.title
    };
    dispatch(editEmployee(clinicId, employeeId, payload));
    setEditMode(false);
  }

  const [selectedItems, setSelectedItems] = useState([]);
  const [initialSelectedItemsData, setInitialSelectedItemsData] = useState([]);
  const [showCancelModal, setShowCancelModal] = useState<boolean>(false);
  const [skillsWasChanged, setSkillsWasChanged] = useState<boolean>(false);

  const onFieldsChange = (e: any) => {
    if (e.length < 2 && !editMode) {
      setEditMode(true);
    }
  };

  function onConfirmCancelClick() {
    form.resetFields();
    setEditMode(false);
    setShowCancelModal(false);
    setSelectedItems(initialSelectedItemsData);
  }

  const onChangeFieldValue = () => {
    if (!editMode) {
      setEditMode(true);
    }
    if (!skillsWasChanged) {
      setSkillsWasChanged(true);
    }
  };

  return (
    employee.role && (
      <Form
        layout="vertical"
        onFinish={onSaveClick}
        form={form}
        initialValues={employee}
        onFieldsChange={onFieldsChange}
        className="profile-form"
      >
        <FormItem label={locale.labels.firstName} name="first_name">
          <Input disabled placeholder={locale.labels.firstName} />
        </FormItem>

        <FormItem label={locale.labels.middleName} name="middle_name">
          <Input disabled placeholder={locale.labels.middleName} />
        </FormItem>

        <FormItem label={locale.labels.lastName} name="last_name">
          <Input disabled placeholder={locale.labels.lastName} />
        </FormItem>

        <Row>
          <Col span={24} md={11}>
            <FormItem label={locale.labels.title} name="title">
              <Input placeholder={locale.labels.title} />
            </FormItem>
          </Col>
          <Col span={24} offset={2} md={11}>
            <FormItem
              label={locale.labels.role}
              hidden={config.disabledForProd}
              name="role_id"
            >
              <Select
                showSearch
                placeholder={locale.placeholders.searchToSelect}
                optionFilterProp="children"
                value={{
                  value: employee.role.id,
                  label: employee.role.name
                }}
                defaultValue={{
                  value: employee.role.id,
                  label: employee.role.name
                }}
                filterOption={(input, option) => (option?.label ?? '').includes(input)}
                filterSort={(optionA: any, optionB: any) =>
                  (optionA?.label ?? '')
                    .toLowerCase()
                    .localeCompare((optionB?.label ?? '').toLowerCase())
                }
                options={rolesOptions()}
              />
            </FormItem>
          </Col>
        </Row>

        <FormItem label={locale.labels.address} name={'address'} initialValue={location()}>
          <TextArea
            style={{ maxWidth: '500px' }}
            disabled
            rows={1}
            placeholder={locale.labels.address}
          />
        </FormItem>
        <FormItem label={locale.labels.skills} name={'skills'}>
          <MultiSkillsSelect
            user={employee}
            onChangeFieldValue={onChangeFieldValue}
            setInitialSelectedItemsData={setInitialSelectedItemsData}
            editMode
            selectedItems={selectedItems}
            setSelectedItems={setSelectedItems}
          />
        </FormItem>
        <ViewDetailsButtonsGroup
          fixed
          accessGroup={userAccess.accesses.employees}
          editMode={editMode}
          showCreateBtn
          onEditClick={() => setEditMode(true)}
          onCancelClick={() => {
            if (form.isFieldsTouched() || skillsWasChanged) {
              setShowCancelModal(true);
            } else {
              setEditMode(false);
            }
          }}
          hideDeleteBtn
        />
        <ConfirmCancelModal
          open={showCancelModal}
          onOk={onConfirmCancelClick}
          onCancel={() => setShowCancelModal(false)}
        />
      </Form>
    )
  );
};

export default EmployeeProfileForm;
