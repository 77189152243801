import '../Finances.scss';

import { Form, Pagination, Row, Spin, Table, TableColumnProps } from 'antd';
import VLXSearch from 'components/VLXSearch';
import { money } from 'helpers/list-helpers';
import useClinicId from 'hooks/useClinicId';
import { useLocale } from 'hooks/useLocale';
import { useSearch } from 'hooks/useSearch';
import { IAppState } from 'interfaces/app-state';
import AddPayment from 'layout/modals/finances/addPayment/AddPayment';
import moment from 'moment';
import React, { useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { getPayments } from 'redux/finances/payments/actions';
import { getOpenedShift } from 'redux/shifts/actions';
import { ShowPaymentResponse } from 'services/clinic/payments/models/ShowPaymentResponse';

import FinanceIcon from '../helpers/FinanceIcon';
import { getPaymentName } from './utils/utils.ts';

const PaymentsList = (): JSX.Element => {
  const dispatch = useDispatch();
  const clinicId = useClinicId();
  const navigate = useNavigate();

  const [addPaymentForm] = Form.useForm();
  const locale = useLocale('private.finances.payments');
  const currencies = useLocale('private.currencies').labels;

  const shifts = useSelector(({ shifts }: IAppState) => shifts);
  const openedShift = shifts?.openedShift;
  const { data, metadata } = useSelector(({ payments }: IAppState) => payments);
  const loading = useSelector(({ payments }: IAppState) => payments.loading);

  const redirectToPaymentDetails = (record: ShowPaymentResponse): { onClick: () => void } => ({
    onClick: (): void => {
      navigate(`/clinic/${clinicId}/finances/payments/${record.id}`);
    }
  });

  const { handleChange, onPageChange, getResults, filterResults } = useSearch({
    action: getPayments,
    clinicId,
    defaultSortKey: 'created_at',
    defaultSortOrder: 'desc'
  });

  useEffect((): void => {
    dispatch(getOpenedShift(clinicId));
  }, [clinicId]);

  const columns = useMemo(
    (): TableColumnProps<ShowPaymentResponse>[] => [
      {
        key: 'payment_type',
        dataIndex: 'payment_type',
        title: '',
        sorter: () => null,
        render: (payment_type) => <FinanceIcon type={payment_type} />,
        onCell: redirectToPaymentDetails
      },
      {
        key: 'name',
        title: locale.labels.name,
        sorter: () => null,
        render: (e) => getPaymentName(e?.name, locale),
        onCell: redirectToPaymentDetails
      },
      {
        key: 'id',
        title: locale.labels.id,
        sorter: () => null,
        render: (e) => e.id,
        onCell: redirectToPaymentDetails
      },
      {
        key: 'date',
        dataIndex: 'date',
        title: locale.labels.date,
        sorter: () => null,
        render: (date) => `${date ? moment(date).format('DD.MM.YYYY HH:mm') : '-'}`,
        onCell: redirectToPaymentDetails
      },
      {
        key: 'amount',
        dataIndex: 'amount',
        title: locale.labels.amount,
        sorter: () => null,
        render: (amount) => (amount ? `${money(amount)} ${currencies.uah}` : '-'),
        onCell: redirectToPaymentDetails
      },
      {
        key: 'category',
        dataIndex: 'category',
        title: locale.labels.category,
        sorter: () => null,
        render: (category) => category ?? '-',
        onCell: redirectToPaymentDetails
      },
      {
        key: 'financial_account',
        dataIndex: 'financial_account',
        title: locale.labels.account,
        sorter: () => null,
        render: (financial_account) => financial_account?.name ?? '-',
        onCell: redirectToPaymentDetails
      },
      {
        key: 'created_by',
        dataIndex: 'created_by',
        title: locale.labels.createdBy,
        sorter: () => null,
        render: (createdBy, e) => userData(createdBy)
        // onCell: redirectToBillDetails
      }
    ],
    [data]
  );

  function userData(user: any): string {
    const title = user?.title ? `(${user.title})` : '';
    return user?.name ? `${user.name} ${title}` : '-';
  }

  return (
    <>
    <VLXSearch filterResults={filterResults} savedFiltersName={'paymentsListFilters'} />
    <Spin spinning={loading} className='payments-list-page-spin-container'>
      <Table
        pagination={false}
        className='accent-header payments-list-table'
        rowKey='id'
        dataSource={data}
        onChange={handleChange}
        columns={columns}
        rowClassName={(record) => (record.deleted ? 'disabled-row' : '')}
      />
      <Row
        style={{
          justifyContent: 'space-between',
          marginTop: 10,
          alignItems: 'center'
        }}
      >
        <Pagination
          defaultCurrent={1}
          pageSize={20}
          current={metadata.page}
          total={metadata.total}
          onChange={onPageChange}
          showSizeChanger={false}
          showLessItems
        />
        <AddPayment form={addPaymentForm} openedShift={openedShift} callbackFunc={getResults}/>
      </Row>
    </Spin>
    </>
  );
};

export default PaymentsList;
