import { AxiosResponse } from 'axios';
import { IPrintCongigurationParams } from 'layout/modals/printConfiguration/PrintConfigurationForm';

import baseClient from '../../baseClient';
import { IPrintServiseModal } from './models/IPrintServiseModal';

export const createPrintConfiguration = async (clinicId: number, data: any) =>
  baseClient.post(`/v1/clinics/${clinicId}/print_configs`, data, {
      headers: { 'Content-Type': 'multipart/form-data' }
    }).then((response: AxiosResponse<IPrintCongigurationParams>) => {
    return response.data;
  });

export const updatePrintConfiguration = async (clinicId: number, data: any, configId: number) =>
  baseClient.patch(`/v1/clinics/${clinicId}/print_configs/${configId}`, data, {
      headers: { 'Content-Type': 'multipart/form-data' }
    }).then((response: AxiosResponse<IPrintServiseModal>) => {
    return response.data;
  });

export const getPrintConfiguration = async (clinicId: number) =>
  baseClient.get(`/v1/clinics/${clinicId}/print_configs`).then((response: AxiosResponse<IPrintServiseModal>) => {
    return response.data;
  });
