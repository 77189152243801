import '../scss/ViewEditInvoice.scss';

import { Button, Col, Row, Tooltip } from 'antd';
import DocumentsTab from 'components/DocumentsTab';
import { TextEditorView } from 'components/TextEditorView';
import { Title } from 'components/Title';
import ViewDetailsButtonsGroup from 'components/ViewDetailsButtonsGroup';
import { useLocale } from 'hooks/useLocale.tsx';
import { useUserAccess } from 'hooks/useUserAccess';
import { IAppState } from 'interfaces/app-state';
import { ILocale } from 'interfaces/locale';
import { AddBillPayment } from 'layout/modals/finances/addBillPayment/AddBillPayment.tsx';
import moment from 'moment';
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { showPaymentsModal } from 'redux/finances/bills/actions.ts';
import { getDocumentsList, mergeInvoiceDocumentsList, removeInvoiceDocument } from 'redux/inventory/invoice/actions';
import { IInvoiceDataItem } from 'services/interfaces/clinics/inventory/invoice/IInvoiceData';

type Props = {
  selectedInvoice: IInvoiceDataItem;
  currencies: Record<string, string>;
  clinicId: number;
  locale: ILocale;
  setShowEditInvoiceModal: (e: boolean) => void;
  editingDisabled?: boolean;
};

export const InvoiceDetailsHeader = (
  {
    selectedInvoice,
    setShowEditInvoiceModal,
    clinicId,
    locale,
    editingDisabled
  }: Props): JSX.Element => {
  const currencies = useLocale('private.currencies').labels;
  const selectedInvoiceDocuments = useSelector(
    ({ invoices }: IAppState) => invoices.selectedInvoiceDocuments
  );
  const bill = useSelector(
    ({ bills }: IAppState) => bills.selectedBill
  );
  const shifts = useSelector(({ shifts }: IAppState) => shifts);
  const openedShift = shifts?.openedShift;

  const dispatch = useDispatch();
  const userAccess = useUserAccess();

  useEffect(() => dispatch(getDocumentsList(clinicId, selectedInvoice?.id)), []);

  const setDocumentsList = (data): void => {
    dispatch(mergeInvoiceDocumentsList(data));
  };

  const removeDocument = (doc) => {
    dispatch(removeInvoiceDocument(clinicId, selectedInvoice?.id, doc?.id));
  };

  return (
    <div style={{ marginBottom: 10 }}>
      <div className='appointment-details-top-container' style={{ marginTop: 0 }}>
        <Row gutter={[20, 0]}>
          <Col span={24} md={12} className='invoice-details-top-container__left-column'>
            <h5>{locale.labels.info}</h5>
            <Col>
              {selectedInvoice?.purchase_date && (
                <>
                  <Title title={`${locale.labels.date}: `} />
                  <span id='purchase-date'>
                    {selectedInvoice?.purchase_date
                      ? moment(selectedInvoice.purchase_date as string).format('DD.MM.YYYY')
                      : '-'}
                  </span>
                </>
              )}
            </Col>
            <Col>
              {selectedInvoice?.provider?.id && (
                <>
                  <Title title={`${locale.labels.provider}: `} />
                  <Link
                    id='provider'
                    className={'custom-links'}
                    to={`/clinic/${clinicId}/inventory/providers/${selectedInvoice?.provider?.id}`}
                  >
                    {selectedInvoice.provider.name}
                  </Link>
                </>
              )}
            </Col>
            <Col>
              {selectedInvoice?.bill_id && (
                <>
                  <Title title={`${locale.labels.bill}: `} />
                  <Link
                    className='custom-links'
                    id='provider'
                    to={`/clinic/${clinicId}/finances/bills/${selectedInvoice?.bill_id}`}
                  >
                    {selectedInvoice?.bill_id}
                  </Link>
                </>
              )}
            </Col>
            <Col>
              <>
                <Title title={`${locale.labels.sum}: `} />
                <span id='total-price'>
                  {selectedInvoice?.total_price ? `${selectedInvoice.total_price} ${currencies.uah}` : 0}
                </span>
              </>
            </Col>
            {bill?.remaining_amount > 0 &&
              <Col>
                <>
                  <Title title={`${locale.labels.remainingAmount}: `} />
                  <span id='remaining-amount'>
                  {bill?.remaining_amount ? `${bill?.remaining_amount} ${currencies.uah}` : 0}
                </span>
                </>
              </Col>
            }
            <Col>
              <>
                <Title title={`${locale.labels.totalItems}: `} />
                <span id='total-items'>
                  {selectedInvoice?.number_of_items ? selectedInvoice.number_of_items : 0}
                </span>
              </>
            </Col>
            <Col>
              {selectedInvoice?.notes && (
                <>
                  <Title title={`${locale.labels.notes}: `} />
                  <TextEditorView value={selectedInvoice?.notes} id='notes' />
                </>
              )}
            </Col>
            <Row className='invoice-details-top-container__buttons-container'>
              {bill?.remaining_amount > 0 &&
                <Tooltip trigger={'hover'} placement='topLeft' title={!openedShift?.open && locale.labels.paymentBlockedClosedShift}>
                  <Button
                    id='add-payment'
                    disabled={!openedShift?.open}
                    onClick={() => dispatch(showPaymentsModal(true))}
                    className='change-status-basic-button'
                    style={{ marginBottom: 0, height: 45 }}
                  >
                    <span className='icofont-money' />
                    <span>{locale.labels.addPayment}</span>
                  </Button>
                </Tooltip>
              }
              {!editingDisabled && <ViewDetailsButtonsGroup
                hideCancelBtn
                hideDeleteBtn
                fixed
                accessGroup={userAccess.accesses.invoices}
                editMode={false}
                onEditClick={() => setShowEditInvoiceModal(true)}
              />}
            </Row>
          </Col>
          <Col span={24} md={12} className='invoice-details-top-container__right-column'>
            <h5>{locale.labels.documents}</h5>
            <DocumentsTab
              uploadDocumentLink={`/v1/clinics/${clinicId}/invoices/${selectedInvoice?.id}/documents`}
              data={selectedInvoiceDocuments}
              setDocumentsList={setDocumentsList}
              removeDocument={removeDocument}
            />
          </Col>
        </Row>
        <AddBillPayment bill={bill} openedShift={openedShift} hideAddButton />
      </div>
    </div>
  );
};
