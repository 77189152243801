export const allStatuses = {
  future: {
    ukUA: 'Запланований',
    enGB: 'Planned',
    color: 'grey'
  },
  in_progress: {
    ukUA: 'В процесі',
    enGB: 'In progress',
    color: 'blue'
  },
  finished: {
    ukUA: 'Завершений',
    enGB: 'Finished',
    color: 'green'
  },
  no_show: {
    ukUA: 'Не зʼявився',
    enGB: 'No-Show',
    color: 'red'
  },
  cancelled: {
    ukUA: 'Скасований',
    enGB: 'Cancelled',
    color: 'red'
  },
  in_stock: {
    ukUA: 'В наявності',
    enGB: 'In stock',
    color: 'green'
  },
  critical_level: {
    ukUA: 'Закінчується',
    enGB: 'Running out',
    color: 'orange'
  },
  out_of_stock: {
    ukUA: 'Закінчився',
    enGB: 'Out of stock',
    color: 'red'
  },
  unpaid: {
    ukUA: 'Очікує на оплату',
    enGB: 'Pending payment',
    color: 'red'
  },
  partially_paid: {
    ukUA: 'Частково оплачений',
    enGB: 'Partially paid',
    color: 'orange'
  },
  overpaid: {
    ukUA: 'Переплачений',
    enGB: 'Overpaid',
    color: 'orange'
  },
  payment_not_required: {
    ukUA: 'Не потребує оплати',
    enGB: 'Payment not required',
    color: 'green'
  },
  deleted: {
    ukUA: 'Видалено',
    enGB: 'Deleted',
    color: 'grey'
  },
  pending: {
    ukUA: 'В очікуванні',
    enGB: 'Pending',
    color: 'orange'
  },
  paid: {
    ukUA: 'Оплачений',
    enGB: 'Paid',
    color: 'green'
  },
  active: {
    ukUA: 'Активний',
    enGB: 'Active',
    color: 'green'
  },
  maintenance: {
    ukUA: 'Обслуговування',
    enGB: 'Maintenance',
    color: 'orange'
  },
  closed: {
    ukUA: 'Закритий',
    enGB: 'Closed',
    color: 'red'
  },
  inactive: {
    ukUA: 'Неактивний',
    enGB: 'Inactive',
    color: 'grey'
  },
  blocked: {
    ukUA: 'Заблокований',
    enGB: 'Blocked',
    color: 'red'
  }
};
