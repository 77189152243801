import { ISortObject } from 'services/interfaces/ISortObject.ts';

import { createClinicPaymentBill } from '../../../services/clinic/bills/billsService';
import { CreateUpdatePaymentRequest } from '../../../services/clinic/payments/models/CreateUpdatePaymentRequest';
import { IndexPaymentsResponse } from '../../../services/clinic/payments/models/IndexPaymentsResponse';
import { ShowPaymentResponse } from '../../../services/clinic/payments/models/ShowPaymentResponse';
import {
  createClinicPayment,
  destroyClinicPayment,
  indexClinicPayments,
  showClinicPayment,
  updateClinicPayment,
} from '../../../services/clinic/payments/paymentsService';
import { getAccountsDetailsPayments } from '../financialAccounts/actions';
import {
  PaymentsResetAction,
  RESET,
  SET_LOADING,
  SET_PAYMENT,
  SET_PAYMENTS,
  SetLoadingAction,
  SetPaymentsAction,
  SetSelectedPaymentAction,
  SHOW_MODAL,
  ShowModalAction,
} from './types';

export const setLoading = (value: boolean): SetLoadingAction => ({
  type: SET_LOADING,
  payload: value
});

export const resetPayments = (): PaymentsResetAction => ({
  type: RESET
});

export const showModal = (value: boolean): ShowModalAction => ({
  type: SHOW_MODAL,
  payload: value
});

export const setPayments = (payments: IndexPaymentsResponse): SetPaymentsAction => ({
  type: SET_PAYMENTS,
  payload: payments
});

export const setSelectedPayment = (data: ShowPaymentResponse | null): SetSelectedPaymentAction => ({
  type: SET_PAYMENT,
  payload: data
});

export const getPayments = (clinicId: number, page?: number, searchValue?: any, sortObject?: ISortObject): any => {
  return dispatch => {
    dispatch(setLoading(true));
    indexClinicPayments(clinicId, page, searchValue, sortObject)
      .then(data => {
        dispatch(setPayments(data));
        dispatch(setLoading(false));
      })
      .catch(() => dispatch(setLoading(false)));
  };
};

export const getPaymentsDetails = (clinicId: number, id: number): any => {
  return dispatch => {
    dispatch(setSelectedPayment(null));
    dispatch(setLoading(true));
    showClinicPayment(clinicId, id)
      .then(data => {
        dispatch(setSelectedPayment(data));
        dispatch(setLoading(false));
      })
      .catch(() => dispatch(setLoading(false)));
  };
};

export const createPayment = (clinicId: number, data, callbackFunc?: () => void): any => {
  return dispatch => {
    dispatch(setLoading(true));
    createClinicPayment(clinicId, data)
      .then(() => {
        if (callbackFunc) {
          callbackFunc();
        } else {
          dispatch(getPayments(clinicId, 1));
          dispatch(getAccountsDetailsPayments(clinicId, data?.account_id));
        }
        dispatch(showModal(false));
      })
      .catch(() => dispatch(setLoading(false)));
  };
};

export const createPaymentBill = (clinicId: number, billId: number, data): any => {
  return dispatch => {
    dispatch(setLoading(true));
    createClinicPaymentBill(clinicId, billId, data)
      .then(() => {
        dispatch(getPayments(clinicId, 1));
        dispatch(showModal(false));
      })
      .catch(() => dispatch(setLoading(false)));
  };
};

export const updatePayment = (clinicId: number, accountId: number, data: CreateUpdatePaymentRequest): any => {
  return dispatch => {
    dispatch(setLoading(true));
    updateClinicPayment(clinicId, accountId, data)
      .then(() => {
        dispatch(getPaymentsDetails(clinicId, accountId));
        dispatch(showModal(false));
      })
      .catch(() => dispatch(setLoading(false)));
  };
};

export const removePayment = (clinicId: number, id: number, callbackFunc?: () => void): any => {
  return dispatch => {
    dispatch(setLoading(true));
    destroyClinicPayment(clinicId, id)
      .then(() => {
        dispatch(getPaymentsDetails(clinicId, id));
        dispatch(setLoading(false));
        if (callbackFunc) {
          callbackFunc();
        }
      })
      .catch(() => dispatch(setLoading(false)));
  };
};
