import '../scss/Provider.scss';

import { Button, Col, Form, FormInstance, Input, Row, Typography } from 'antd';
import PhoneFieldFormItem from 'components/PhoneFieldFormItem';
import { TextEditor } from 'components/TextEditor';
import useClinicId from 'hooks/useClinicId';
import { useLocale } from 'hooks/useLocale';
import React, { ReactNode, useEffect, useMemo } from 'react';
import { useDispatch } from 'react-redux';
import { createProvider, updateProvider } from 'redux/inventory/providers/actions';
import { ISelectedProvidersItem } from 'services/interfaces/clinics/inventory/providers/ISelectedProvidersItemsData';
import { maxRule, patternRule, PATTERNS, requiredRule } from 'utils/form-rules';

const { TextArea } = Input;

type Props = {
  form: FormInstance;
  children: ReactNode;
  formEditMode?: boolean;
  onFieldsChange?: (changedFields: any, allFields: any) => void;
  initialValues?: ISelectedProvidersItem;
  callBackFunction?: (data: ISelectedProvidersItem) => void;
  defaultProviderNameValue?: string;
};

export const AddProviderForm = (
  {
    form,
    initialValues,
    formEditMode,
    children,
    onFieldsChange,
    callBackFunction,
    defaultProviderNameValue
  }: Props): JSX.Element => {
  const dispatch = useDispatch();
  const locale = useLocale('private.inventory.providers');
  const clinicId = useClinicId();

  useEffect(() => {
    form.resetFields();
  }, [initialValues]);

  const onSubmit = (data: any): void => {
    const apiData = { ...data };

    apiData.emails = apiData.emails
      ? JSON.stringify(
        apiData.emails
          .filter((item) => item.email)
          .map(({ email, description }) => ({ email, description }))
      )
      : [];

    apiData.phone_numbers = JSON.stringify(apiData.phone_numbers);

    if (formEditMode) {
      dispatch(updateProvider(clinicId, initialValues.id, apiData, callBackFunction));
    } else {
      dispatch(createProvider(clinicId, apiData, callBackFunction));
    }
  };

  const parseValues = useMemo(() => {
    if (initialValues) {
      const data = { ...initialValues };

      const emails = JSON.parse(initialValues.emails);
      data.emails = emails.length ? emails : [{}];
      const phoneNumbers = JSON.parse(initialValues.phone_numbers);
      data.phone_numbers = phoneNumbers.length ? phoneNumbers : [{}];

      return data;
    }
    return {
      emails: [{}],
      phone_numbers: [{}]
    };
  }, [initialValues]);

  const EmailFormItem = ({ name }): JSX.Element => (
    <Form.Item
      style={{ marginBottom: 0 }}
      name={[name, 'email']}
      rules={[patternRule(PATTERNS.EMAIL, locale.messages.emailNotValid)]}
    >
      <Input placeholder={locale.labels.emails} />
    </Form.Item>
  );

  const PhoneFormItem = ({ name }): JSX.Element => (
    <PhoneFieldFormItem
      formItemPhonePrefix={[name, 'country_code']}
      formItemPhoneNumber={[name, 'phone_number']}
      hideLabel
    />
  );

  const ContactsFormItem = ({ title, listName }): JSX.Element => (
    <div style={{ marginBottom: 15 }}>
      <Typography className='form-label'>
        {listName !== 'emails' && <span style={{ color: '#ff4d4f', paddingRight: 4 }}>*</span>}
        {title}
      </Typography>
      <Form.List name={listName}>
        {(fields, { add, remove }) => (
          <>
            {fields?.map(({ key, name }) => (
              <Row key={key} gutter={[20, 10]}>
                <Col span={24} md={8}>
                  {listName === 'emails' ? (
                    <EmailFormItem name={name} />
                  ) : (
                    <PhoneFormItem name={name} />
                  )}
                </Col>
                <Col span={24} md={9} xs={key > 0 ? 21 : 24}>
                  <Form.Item name={[name, 'description']}>
                    <Input placeholder={locale.labels.description} />
                  </Form.Item>
                </Col>
                <Col span={1}>
                  <Button
                    onClick={(e) => {
                      remove(name);
                      formEditMode && onFieldsChange([{ e }], [{ e }]);
                    }}
                    danger
                    type='primary'
                    shape={'circle'}
                    className={'inventory-update-buttons'}
                  >
                    <span className='icofont icofont-trash' />
                  </Button>
                </Col>
              </Row>
            ))}
            <Row>
              <Button
                className='icofont icofont-plus'
                type={'primary'}
                style={{
                  borderRadius: 30,
                  fontWeight: 'bold'
                }}
                onClick={() => add()}
              >
                {locale.buttons.add}
              </Button>
            </Row>
          </>
        )}
      </Form.List>
    </div>
  );

  return (
    <Form
      onFinish={onSubmit}
      onFieldsChange={onFieldsChange}
      autoComplete='off'
      layout='vertical'
      form={form}
      initialValues={parseValues}
    >
      <Row gutter={[20, 10]}>
        <Col span={24} md={12}>
          <Form.Item
            label={locale.labels.name}
            name={'name'}
            rules={[requiredRule()]}
            initialValue={defaultProviderNameValue}
          >
            <Input />
          </Form.Item>
          <Form.Item
            label={locale.labels.address}
            name='address'
            rules={[maxRule(255, locale.errors.length_rule_255)]}
          >
            <TextArea rows={2} />
          </Form.Item>
          <TextEditor label={locale.labels.comments} name={['comments']} rows={3} />
        </Col>
      </Row>
      <ContactsFormItem listName='phone_numbers' title={locale.labels.contactPhone} />
      <ContactsFormItem listName='emails' title={locale.labels.emails} />

      {children}
    </Form>
  );
};
