import './scss/Provider.scss';

import { Button, Card, Col, Menu, MenuProps, Row, Spin } from 'antd';
import { DebtStatus } from 'components/DebtStatus';
import { TextEditorView } from 'components/TextEditorView';
import { useLocale } from 'hooks/useLocale';
import { useUserAccess } from 'hooks/useUserAccess';
import { IAppState } from 'interfaces/app-state';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link, Params, useParams } from 'react-router-dom';
import {
  getProviderById,
  setSelectedProviderInvoicesData,
  setShowEditProviderModal
} from 'redux/inventory/providers/actions';
import { ProvidersListState } from 'redux/inventory/providers/reducer';

import { EditProvider } from './modal/EditProvider';
import { ProviderHistoryTab } from './ProviderHistoryTab';

export const ProviderDetails = (): JSX.Element => {
  const dispatch = useDispatch();
  const { clinicId, providerId }: Readonly<Params<string>> = useParams();
  const userAccess = useUserAccess();
  const locale = useLocale('private.inventory.providers');

  const [currentTab, setCurrentTab] = useState('history');

  const { selectedProviderData } = useSelector<IAppState, ProvidersListState>(
    ({ providers }) => providers
  );
  const { loading } = useSelector<IAppState, ProvidersListState>(({ providers }) => providers);

  useEffect(() => {
    dispatch(getProviderById(Number(clinicId), Number(providerId)));

    return () => {
      dispatch(setSelectedProviderInvoicesData(null));
    };
  }, []);

  const items: MenuProps['items'] = [
    {
      label: locale.tabs.history,
      key: 'history'
    }
  ];

  const changeTab: MenuProps['onClick'] = (e) => {
    setCurrentTab(e.key);
  };

  const getParsedContacts = (list: string, key: string, linkTo: string): JSX.Element | string => {
    const data = JSON.parse(list)?.filter((item) => !!item?.[key]);
    if (!data.length) return '-';

    return data.map((item, index) => (
      <Row gutter={24} key={index} align="middle">
        <Col flex="0 1 100px">
          <Link
            className="additional_contacts_number"
            to={`${linkTo}:${item?.country_code ? `+${item?.country_code}` : ''}${item[key]}`}
            style={{ fontSize: 15, marginRight: 20 }}
          >
            {item?.country_code ? `+${item?.country_code}` : ''}
            {item[key]}
          </Link>
        </Col>
        <Col flex="0 1 370px">
          <span style={{ fontSize: 15 }}>{item?.description}</span>
        </Col>
      </Row>
    ));
  };

  const ProviderDetail = ({
                            icon,
                            children,
                            id
                          }: React.PropsWithChildren<{ icon: string, id?: string }>): JSX.Element => (
    <Row align="middle" className="provider-info-container__row">
      <Col className="provider-info-container__col-left provider-icon">
        <span className={`icofont-${icon} info-icon`} />
      </Col>
      <Col className="provider-info-container__col-right" id={id || ''}>
        {children}
      </Col>
    </Row>
  );

  return (
    <Spin spinning={loading}>
      {!!selectedProviderData && (
        <>
          <Col span={24} style={{ padding: '0 12px' }}>
            <Card
              className="ant-card-bordered personal-info-card with-shadow patient-info-container stock-details-info-container provider-info-container">
              <Row>
                <Col xl={12} span={24}>
                  <ProviderDetail icon="briefcase-1" id={'provider-name'}>
                    <span style={{ marginRight: 15, fontSize: 18 }}>{selectedProviderData.name}</span>
                    <DebtStatus debt={selectedProviderData?.debt} />
                  </ProviderDetail>
                  <ProviderDetail icon="ui-touch-phone" id={'provider-phones'}>
                    {getParsedContacts(selectedProviderData.phone_numbers, 'phone_number', 'tel')}
                  </ProviderDetail>
                  <ProviderDetail icon="ui-message" id={'provider-emails'}>
                    {getParsedContacts(selectedProviderData.emails, 'email', 'mailto')}
                  </ProviderDetail>
                </Col>
                <Col xl={12} span={24}>
                  {selectedProviderData.address && (
                    <ProviderDetail icon="location-pin" id={'provider-location'}>
                      {selectedProviderData.address}
                    </ProviderDetail>)}
                  {selectedProviderData.comments && (
                    <ProviderDetail icon="comment">
                      <div className="comments-container" id={'provider-comments'}>
                        <TextEditorView value={selectedProviderData.comments} />
                      </div>
                    </ProviderDetail>
                  )}
                </Col>
              </Row>
              <Row className="provider-info-container__edit-button-container">
                <Button
                  type="primary"
                  style={{ borderRadius: 30 }}
                  onClick={() => dispatch(setShowEditProviderModal(true))}
                >
                  <span
                    className="icofont icofont-edit-alt mr-2 sticky-btn-icon"
                    style={{ fontSize: '1.1em' }}
                  />
                  <span>{locale.buttons.edit}</span>
                </Button>
              </Row>
            </Card>
          </Col>
          <Col span={24}>
            <Card className="ant-card-bordered personal-info-card with-shadow">
              <Menu
                onClick={changeTab}
                selectedKeys={[currentTab]}
                mode="horizontal"
                items={items}
                style={{ marginBottom: 20 }}
              />
              {currentTab === 'history' && <ProviderHistoryTab />}
            </Card>
          </Col>
        </>
      )}
      {userAccess.accesses.providers.edit && <EditProvider initialValues={selectedProviderData} />}
    </Spin>
  );
};
