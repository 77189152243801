export const sales = {
  enGB: {
    labels: {
      location: 'Location',
      name: 'Name',
      pricePerPackage: 'Price per package',
      price: 'Price',
      pricePerUnit: 'Price per unit',
      quantityPackage: 'Quantity (package)',
      quantityUnit: 'Quantity (unit)',
      quantity: 'Quantity',
      uah: 'uah',
      per: 'per',
      date: 'Date',
      client: 'Client',
      phoneNumber: 'Phone number',
      numberOfPets: 'Number of pets',
      stock: 'Stock',
      editStock: 'Edit Stock',
      addStock: 'Add Stock',
      sum: 'Sum',
      count: 'Count',
      discount: 'Discount',
      fiscal: 'Fiscal',
      itemsCount: 'Items count',
      chooseItem: 'Choose item',
      totalPrice: 'Total price',
      includedInServicePrice: 'Included in service price',
      unsavedChanges: 'Unsaved changes',
      priceWithDiscount: 'Price with discount',
      paymentBlockedClosedShift: 'Shift is closed. Open the shift to create a payment.',
      user: 'User',
      createdBy: 'Created by',
      paymentStatus: 'Payment status',
      saleItem: 'Sale item',
      totalBilledAmount: 'Total',
      totalPayedAmount: 'Paid',
      paid: 'Paid',
      remainingAmount: 'To be paid (remaining)',
      remaining: 'remaining',
      actions: 'Actions',
      outOfStock: 'Out of stock',
      defaultPrice: '(default price) ',
      remainingTooltip: 'Maximum: %1 (remaining %2)',
      inPrice: 'in price',
      inLocation: 'in location',
      unassigned: 'Unassigned',
      seller: "Seller",
      salesCount: 'Sales count',
      totalIncome: 'Total income',
      totalBilled: 'Total billed',
      currentShift: 'Current shift',
      allSales: 'All sales',
      deletedSales: 'Deleted sales'
    },
    buttons: {
      edit: 'edit',
      delete: 'delete',
      createNew: 'Create new',
      addPayment: 'Add payment',
      addNewItem: 'Add',
      saveNewItem: 'Save',
      addSale: 'Add new sale'
    },
    messages: {
      notEnoughInLocation: 'Not enough items in the selected location',
      notEnoughWithPrice: 'Not enough items with the selected price',
      priceShouldBeSelected: 'Please select price',
      locationShouldBeSelected: 'Please select location',
      dataSavedSuccessfully: 'Data saved successfully',
      noEmptyQuantityFields: 'Please fulfill at least one quantity fields',
      deleteItemMsg: 'Are you sure you want to delete %placeholder% from this sale?',
      clearSalesForm: 'Are you sure you want to clear the form?'
    },
    placeholders: {
      selectClient: 'Select client'
    }
  },
  ukUA: {
    labels: {
      location: 'Локація',
      name: 'Назва',
      pricePerPackage: 'Ціна за пакування',
      price: 'Ціна',
      pricePerUnit: 'Ціна за одиницю',
      quantityPackage: 'Кількість (упакування)',
      quantityUnit: 'Кількість (одиниці)',
      quantity: 'К-cть',
      uah: 'грн',
      per: 'за',
      date: 'Дата',
      editStock: 'Редагувати Товар',
      chooseItem: 'Оберіть товар',
      count: 'Кількість',
      client: 'Клієнт',
      addStock: 'Додати товар',
      phoneNumber: 'Номер телефону',
      numberOfPets: 'Кількість улюбленців',
      saleItem: 'Формат',
      includedInServicePrice: 'Включено в ціну послуги',
      unsavedChanges: 'Незбережені зміни',
      sum: 'Сума',
      discount: 'Знижка',
      stock: 'Назва',
      fiscal: 'Фіскальний',
      itemsCount: 'Кількість продуктів',
      totalPrice: 'Повна вартість',
      priceWithDiscount: 'Вартість зі знижкою',
      paymentBlockedClosedShift: 'Зміна закрита. Відкрийте зміну, щоб дозволити проведення оплат.',
      user: 'Користувач',
      createdBy: 'Створив(-ла)',
      paymentStatus: 'Статус оплати',
      totalBilledAmount: 'Сума',
      totalPayedAmount: 'Сплачено',
      remainingAmount: 'Залишок до оплати',
      remaining: 'залишок',
      actions: 'Дії',
      paid: 'Оплачено',
      outOfStock: 'Немає в наявності',
      defaultPrice: '(за замовч.) ',
      remainingTooltip: 'Максимум: %1 (залишок %2)',
      inPrice: 'по ціні',
      inLocation: 'по локації',
      unassigned: 'Не розподілено',
      seller: "Продавець",
      salesCount: 'Кількість продажів',
      totalIncome: 'Загальний дохід',
      totalBilled: 'Сума виставлених рахунків',
      currentShift: 'Поточна зміна',
      allSales: 'Всі продажі',
      deletedSales: 'Видалені продажі'
    },
    buttons: {
      edit: 'редагувати',
      delete: 'видалити',
      createNew: 'Створити нового',
      addNewItem: 'Додати',
      saveNewItem: 'Зберегти',
      addPayment: 'Додати оплату',
      addSale: 'Додати новий продаж'
    },
    messages: {
      notEnoughInLocation: 'Недостатньо товару у вибраній локації',
      notEnoughWithPrice: 'Недостатньо товару з вказаною ціною',
      priceShouldBeSelected: 'Будь ласка, виберіть ціну',
      locationShouldBeSelected: 'Будь ласка, виберіть локацію зберігання товару',
      dataSavedSuccessfully: 'Дані успішно збережено',
      noEmptyQuantityFields: 'Будь ласка, заповніть одне з полів з кількістю',
      deleteItemMsg: 'Чи справді ви хочете видалити %placeholder% зі списку товарів?',
      clearSalesForm: 'Чи справді ви хочете очистити форму?'
    },
    placeholders: {
      selectClient: 'Оберіть клієнта'
    }
  }
};
