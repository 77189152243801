import 'moment/locale/uk';
import '../appointments/AppointmentsList.scss';

import { Button, Pagination, Popover, Row, Table, TableColumnProps, Tooltip } from 'antd';
import ListFieldWithTooltip from 'components/ListFieldWithTooltip';
import { appointmentStatuses, paymentStatuses } from 'constants/dictionary/default/selectOptions';
import { blacklistedStatus } from 'helpers/ViewClientHelper';
import { useLocale } from 'hooks/useLocale';
import useLocalizedList from 'hooks/useLocalizedList';
import { useSearch } from 'hooks/useSearch';
import { IAppState } from 'interfaces/app-state';
import { IAppointment } from 'interfaces/appointment';
import moment from 'moment';
import React, { useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useNavigate } from 'react-router-dom';
import {
  getUserAppointments,
  patchAppointmentOnTheList,
  resetAppointments,
  setInitialDataForAppointment,
  setSelectedAppointment,
  setSelectedAppointmentHealthRecords,
  setSelectedAppointmentPatients
} from 'redux/appointments/actions';
import { setSelectedClient } from 'redux/clients/actions';
import { setSelectedPatienVaccinationst } from 'redux/patients/actions';
import { getTime } from 'utils/get-time';

import { IncompleteHealthRecordMarker } from '../../components/IncompleteHealthRecordMarker';
import {
  renderAppointmentStatus,
  renderPaymentStatus
} from '../appointments/AppointmentsBaseHelper';
import CancellationForm from '../appointments/components/CancellationForm';

type Props = {
  filter: { name: string; value: number | string }[];
};

export const DashboardPageAppointmentsList = ({ filter }: Props): JSX.Element => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const locale = useLocale('private.appointments.event');
  const localizedStatuses = useLocalizedList(appointmentStatuses);
  const localizedPaymentStatuses = useLocalizedList(paymentStatuses);

  const { data, metadata } = useSelector(({ appointments }: IAppState) => appointments);
  const loading = useSelector(({ appointments }: IAppState) => appointments.loading);
  const userLocale = useSelector(({ user }: IAppState) => user.locale);

  const [currentAppointmentId, setCurrentAppointmentId] = useState(null);
  const [currentClinicId, setCurrentClinicId] = useState(null);
  const [showCancellationModal, setShowCancellationModal] = useState(false);

  useEffect((): void => {
    if (userLocale === 'ukUA') {
      moment.locale('uk');
    } else {
      moment.locale('en');
    }
  }, [userLocale]);

  useEffect(() => {
    dispatch(setSelectedClient(null));
    dispatch(setSelectedAppointment(null));
    dispatch(setSelectedAppointmentHealthRecords(null));
    dispatch(setSelectedPatienVaccinationst(null));
    dispatch(setInitialDataForAppointment(null));
    dispatch(setSelectedAppointmentPatients(null));

    return () => {
      dispatch(resetAppointments());
    };
  }, []);

  const { handleChange, onPageChange, getResults } = useSearch({
    action: getUserAppointments,
    defaultSortKey: 'created_at',
    defaultSortOrder: 'desc',
    defaultSearchParams: true,
    customFilters: filter
  });

  const redirectToAppointment = (record: IAppointment): { onClick: () => void } => ({
    onClick: () => {
      navigate(`/clinic/${record.clinic_id}/appointments/${record.id}`);
    }
  });

  const onStatusChange = (newStatus: string, appointment: IAppointment): void => {
    const status = { properties: [{ name: 'status', value: newStatus }] };
    dispatch(patchAppointmentOnTheList(appointment.clinic_id, appointment.id, status, getResults));
  };

  const getPatientView = (e: IAppointment): JSX.Element[] | '-' => {
    if (e.patients?.length) {
      return e.patients.map((el) => (
        <div key={el.id}>
          <Link className={'custom-links'} to={`/clinic/${e.clinic_id}/patients/${el.id}`}>
            {ListFieldWithTooltip(el.name)}
          </Link>
        </div>
      ));
    }
    return '-';
  };

  const canStart = (appointment: IAppointment): boolean => {
    return appointment.status === 'future';
  };

  const canCancel = (appointment: IAppointment): boolean => {
    return appointment.status === 'future' || appointment.status === 'in_progress';
  };

  const Content = (appointment: IAppointment): JSX.Element => (
    <div>
      <p style={{ marginBottom: '2px' }}>
        {locale.labels.cancelledBy}:{' '}
        {`${appointment?.cancellation?.cancelled_by?.name} (${appointment?.cancellation?.cancelled_by?.title})`}
      </p>
      <p style={{ marginBottom: '2px' }}>
        {locale.labels.reason}: {`${appointment?.cancellation?.reason}`}
      </p>
    </div>
  );

  const defineStatus = (appointment: IAppointment): JSX.Element => {
    if (!appointment.status) {
      return null;
    }
    if (appointment.status === 'cancelled') {
      return (
        <div style={{ display: 'flex', alignItems: 'center', flexWrap: 'nowrap' }}>
          {renderAppointmentStatus(appointment.status, localizedStatuses)}
          <Popover
            // onOpenChange={onOpenChange}
            placement='topLeft'
            title={locale.labels.appointmentCancelled}
            content={Content(appointment)}
          >
            <span className='icofont-info-circle' style={{ fontSize: '15px' }} />
          </Popover>
        </div>
      );
    }
    if (appointment.status === 'finished') {
      return renderPaymentStatus(appointment.payment_status, localizedPaymentStatuses);
    } else {
      return renderAppointmentStatus(appointment.status, localizedStatuses);
    }
  };

  const columns = useMemo(
    (): TableColumnProps<IAppointment>[] => [
      {
        key: 'clinic_name',
        dataIndex: 'clinic_name',
        title: locale.labels.clinicName,
        sorter: () => null,
        onCell: redirectToAppointment,
        render: (clinic_name) => <>{clinic_name}</>,
        width: '10%'
      },
      {
        key: 'title',
        dataIndex: 'title',
        title: locale.labels.visitReason,
        sorter: () => null,
        onCell: redirectToAppointment,
        render: (title) => title,
        width: '15%'
      },
      {
        key: 'start_time',
        title: locale.labels.time,
        sorter: () => null,
        onCell: redirectToAppointment,
        render: getTime
      },
      {
        key: 'status',
        title: locale.labels.status,
        sorter: () => null,
        render: (e) => {
          return (
            <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
              <span style={{ marginRight: 6 }}>{defineStatus(e)}</span>
              <IncompleteHealthRecordMarker appointment={e} locale={locale} />
            </div>
          );
        }
      },
      {
        key: 'patient',
        title: locale.labels.patient,
        sorter: () => null,
        render: (e) => getPatientView(e)
      },
      {
        key: 'client_phone',
        title: locale.labels.client,
        sorter: () => null,
        render: (e: IAppointment) => (
          <>
            {
              <Link
                to={`/clinic/${e?.clinic_id}/clients/${e?.client?.id}`}
                className={'custom-links'}
              >
                {ListFieldWithTooltip(e.client?.name, 15)}{' '}
              </Link>
            }
            <Link
              to={`tel:${e.client?.country_code}${e.client?.phone_number}`}
              className='d-flex align-baseline nowrap'
            >
              (<span className='icofont icofont-ui-cell-phone mr-1' style={{ fontSize: 16 }} />
              {`+${e.client?.country_code}${e.client?.phone_number}`})
            </Link>
            {blacklistedStatus(e.client)}
          </>
        )
      },
      {
        key: 'doctor',
        dataIndex: 'doctor',
        title: locale.labels.doctor,
        sorter: () => null,
        onCell: redirectToAppointment,
        render: (doctor) => ListFieldWithTooltip(doctor?.name)
      },
      {
        key: 'location',
        title: locale.labels.location,
        sorter: () => null,
        onCell: redirectToAppointment,
        render: (e) => (e?.location ? ListFieldWithTooltip(e.location?.details) : '-')
      },
      {
        key: 'id',
        title: locale.labels.actions,
        render: (e) => (
          <div className='buttons-list nowrap'>
            <Tooltip
              trigger={'hover'}
              placement='topLeft'
              title={!canStart(e) ? locale.messages.appointmentCanNotBeStartedList : null}
            >
              <Button
                className='change-appointment-status-button'
                disabled={!canStart(e)}
                onClick={() => onStatusChange('in_progress', e)}
                shape='circle'
                type='primary'
              >
                <span className='icofont-play-alt-2' style={{ fontSize: '17px' }} />
              </Button>
            </Tooltip>
            <Tooltip
              trigger={'hover'}
              placement='topLeft'
              title={!canCancel(e) ? locale.messages.appointmentCanNotBeCancelledList : null}
            >
              <Button
                className='change-appointment-status-button'
                disabled={!canCancel(e)}
                onClick={() => {
                  setCurrentAppointmentId(e.id);
                  setCurrentClinicId(e.clinic_id);
                  setShowCancellationModal(true);
                }}
                shape='circle'
                danger
              >
                <span className='icofont-ui-block' style={{ fontSize: '17px' }} />
              </Button>
            </Tooltip>
          </div>
        )
      }
    ],
    [data]
  );

  return (
    <>
      {!!data && (
        <Table
          loading={loading}
          pagination={false}
          onChange={handleChange}
          className='accent-header appointments-list-table'
          rowKey='id'
          dataSource={data}
          columns={columns}
          scroll={{ x: 'max-content' }}
        />
      )}
      <Row
        style={{
          justifyContent: 'space-between',
          marginTop: 10,
          alignItems: 'center'
        }}
      >
        {!loading && (
          <Pagination
            defaultCurrent={1}
            showSizeChanger={false}
            showLessItems
            pageSize={20}
            current={metadata.page}
            total={metadata.total}
            onChange={onPageChange}
          />
        )}
      </Row>
      <CancellationForm
        listView={true}
        appointmentId={currentAppointmentId}
        clinicId={currentClinicId}
        showCancellationModal={showCancellationModal}
        setShowCancellationModal={setShowCancellationModal}
        currentPage={metadata.page}
        searchArray={filter}
        getResults={getResults}
        getUserAppointmentsDataAfterSuccess
      />
    </>
  );
};
