import './EventsCalendar.scss';

import { Modal, Typography } from 'antd';
import React from 'react';
import { EventsIndexResponse } from 'services/interfaces/clinics/events/EventsIndexResponse';
import { getTime } from 'utils/get-time';

const { Title } = Typography;

type Props = {
  events: EventsIndexResponse[];
  onCancel: () => void;
};

export const AllDayEventsModal = ({ events, onCancel }: Props): JSX.Element => {
  return (
    <Modal
      open
      onCancel={onCancel}
      footer={null}
      width={'auto'}
      centered
      styles={{
        content: {
          minWidth: 350
        },
        mask: {
          backgroundColor: 'transparent'
        }
      }}
    >
      <>
        {events.map((event, i) => (
          <React.Fragment key={event.id}>
            <Title level={5}>
              {i + 1}. {event?.title}
            </Title>
            {getTime(event)}
          </React.Fragment>
        ))}
      </>
    </Modal>
  );
};
