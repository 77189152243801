import './scss/PrintPricesForm.scss';

import { Button, Col, Form, InputNumber, Row, Select } from 'antd';
import { useLocale } from 'hooks/useLocale';
import { ILocale } from 'interfaces/locale';
import { useGetResidual } from 'pages/inventory/stock/utils/useGetResidual';
import React from 'react';
import { IStockItem } from 'services/interfaces/clinics/inventory/stock/IStockItem';
import { requiredRule } from 'utils/form-rules';

const { Option } = Select;

type Props = React.PropsWithChildren<{
  id: number;
  remove?: (item) => void;
  locale: ILocale;
  stock: IStockItem;
  prices: any;
}>;

export const PricesListItem = ({
  id,
  remove,
  locale,
  stock,
  prices
}: Props): JSX.Element => {
  const currencies = useLocale('private.currencies').labels;

  const { unitOfMeasurement, packagingFormat, noPackageFormat } =
    useGetResidual(stock);

  const packagingPrintOptions = <>
    <Option key='bothPrices' title={locale.labels.cash} value='both'>
      {locale.labels.bothPrices}
    </Option>
    <Option key='priceForPackage' title={locale.labels.cash} value='package'>
      {locale.labels.priceForPackage}
    </Option>
    <Option key='priceForPiece' title={locale.labels.cash} value='piece'>
      {locale.labels.priceForPiece}
    </Option>
  </>;

  const pricesOptions = prices?.map(
    (item): JSX.Element => (
      <Option key={item.id} value={item.id}>
        {!noPackageFormat && `${packagingFormat}: ${item.price_per_package} ${currencies.uah} / `}
        {unitOfMeasurement}: {item.price_per_unit} {currencies.uah}
      </Option>
    )
  );

  return (
    <Row key={id} gutter={24} className='invitation-creation-form-row'>
      <Col lg={8} md={12} sm={12} xs={24}>
        <Form.Item label={locale.labels.price} name={[id, 'price']} rules={[requiredRule()]}>
          <Select>{pricesOptions}</Select>
        </Form.Item>
      </Col>
      {!noPackageFormat && <Col lg={6} md={12} sm={12} xs={24}>
        <Form.Item label={locale.labels.print} name={[id, 'print']} initialValue={noPackageFormat ? 'piece' : 'both'}>
          <Select disabled={noPackageFormat}>{packagingPrintOptions}</Select>
        </Form.Item>
      </Col>}
      <Col lg={6} md={12} sm={12} xs={24}>
        <Form.Item initialValue={1} label={locale.labels.quantity} name={[id, 'quantity']} rules={[requiredRule()]}>
          <InputNumber />
        </Form.Item>
      </Col>

      <Col lg={4} md={8} sm={12} xs={12}>
        <Form.Item label={' '}>
          <Button
            onClick={() => {
              remove(id);
            }}
            danger
            type='primary'
            shape={'circle'}
            className='remove-btn'
          >
            <span className='icofont icofont-trash mr-2' />
          </Button>
        </Form.Item>
      </Col>
    </Row>
  );
};
