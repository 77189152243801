import './scss/ViewEditInvoice.scss';

import { Table, TableColumnProps } from 'antd';
import ListFieldWithTooltip from 'components/ListFieldWithTooltip';
import { money } from 'helpers/list-helpers';
import { useLocale } from 'hooks/useLocale';
import React, { useMemo } from 'react';
import { Link, Params, useParams } from 'react-router-dom';
import { IInvoiceStockItem } from 'services/clinic/inventory/invoices/inventoryItems/models/showInventoryItemResponse';

interface Props {
  data: IInvoiceStockItem[];
}

export const ViewEditInvoiceItemsTable = ({
  data
}: Props): JSX.Element => {
  const { clinicId }: Params<string> = useParams();
  const locale = useLocale('private.inventory.invoices');
  const currencies = useLocale('private.currencies').labels;

  const columns = useMemo(
    (): TableColumnProps<IInvoiceStockItem>[] => [
      {
        key: 'name',
        title: locale.labels.name,
        render: (e) =>
          ListFieldWithTooltip(
            <Link
              className={'custom-links'}
              to={`/clinic/${clinicId}/inventory/stock/${e.stock.id}`}
            >
              {e.stock.name}
            </Link>
          )
      },
      {
        key: 'series',
        title: locale.labels.series,
        render: (e) => e.series ?? '-'
      },
      {
        key: 'initial_quantity',
        title: locale.labels.purchaseQuantity,
        render: (e) => e.initial_quantity ?? '-'
      },
      {
        key: 'price_per_unit',
        title: locale.labels.purchasePrice,
        render: (e) => `${e.price_per_unit ? `${money(e.price_per_unit)} ${currencies.uah}` : '-'}`
      },
      {
        key: 'selling_price',
        title: locale.labels.sellingPrice,
        render: (e) => `${e.selling_price ? `${money(e.selling_price)} ${currencies.uah}` : '-'}`
      }
    ],
    [data]
  );

  return (
    <Table
      pagination={false}
      className='accent-header stock-list'
      rowKey='id'
      dataSource={data}
      columns={columns}
    />
  );
};
