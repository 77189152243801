import {
  createClinicBill,
  createClinicPaymentBill,
  destroyClinicBill,
  indexClinicBills,
  showClinicBill,
  showClinicBillPayments,
  updateClinicBill
} from 'services/clinic/bills/billsService';
import { CreateUpdateBillRequest } from 'services/clinic/bills/models/CreateUpdateBillRequest';
import { IBillDetails } from 'services/clinic/bills/models/IBillDetails';
import { IndexBillsResponse } from 'services/clinic/bills/models/IndexBillsResponse';
import { ISortObject } from 'services/interfaces/ISortObject.ts';

import { ShowPaymentResponse } from '../../../services/clinic/payments/models/ShowPaymentResponse.ts';
import { getAppointmentBill, showBillModal } from '../../appointments/actions';
import {
  BillsResetAction,
  RESET,
  SET_BILL,
  SET_BILL_PAYMENTS,
  SET_BILLS,
  SET_BILLS_LOADING,
  SetBillsAction,
  SetLoadingAction,
  SetSelectedBillAction,
  SetSelectedBillPaymentsAction,
  SHOW_MODAL,
  SHOW_PAYMENTS_MODAL,
  ShowModalAction,
  ShowPaymentsModalAction
} from './types';

export const setLoading = (value: boolean): SetLoadingAction => ({
  type: SET_BILLS_LOADING,
  payload: value
});

export const resetBills = (): BillsResetAction => ({
  type: RESET
});

export const showModal = (value: boolean): ShowModalAction => ({
  type: SHOW_MODAL,
  payload: value
});

export const showPaymentsModal = (value: boolean): ShowPaymentsModalAction => ({
  type: SHOW_PAYMENTS_MODAL,
  payload: value
});

export const setBills = (bills: IndexBillsResponse): SetBillsAction => ({
  type: SET_BILLS,
  payload: bills
});

export const setSelectedBill = (data: IBillDetails | null): SetSelectedBillAction => ({
  type: SET_BILL,
  payload: data
});

export const setSelectedBillPayments = (
  data: IBillDetails[] | null
): SetSelectedBillPaymentsAction => ({
  type: SET_BILL_PAYMENTS,
  payload: data
});

export const getBills = (clinicId: number, page?: number, searchValue?: any, sortObject?: ISortObject): any => {
  return (dispatch) => {
    dispatch(setLoading(true));
    indexClinicBills(clinicId, page, searchValue, sortObject)
      .then((data) => {
        dispatch(setBills(data));
      })
      .finally(() => dispatch(setLoading(false)))
      .catch(() => dispatch(setLoading(false)));
  };
};

export const getBillsDetails = (
  clinicId: number,
  id: number,
  callBackFunction?: (data: IBillDetails) => void
): any => {
  return (dispatch) => {
    dispatch(setSelectedBill(null));
    dispatch(setLoading(true));
    showClinicBill(clinicId, id)
      .then((data) => {
        if (callBackFunction) {
          callBackFunction(data);
        }
        dispatch(setSelectedBill(data));
      })
      .finally(() => dispatch(setLoading(false)))
      .catch(() => dispatch(setLoading(false)));
  };
};

export const getBillsPaymentsDetails = (clinicId: number, id: number): any => {
  return (dispatch) => {
    dispatch(setLoading(true));
    showClinicBillPayments(clinicId, id)
      .then((data) => {
        dispatch(setSelectedBillPayments(data));
      })
      .finally(() => dispatch(setLoading(false)))
      .catch(() => dispatch(setLoading(false)));
  };
};

export const createBill = (clinicId: number, data, callbackFunc?: any): any => {
  return (dispatch) => {
    dispatch(setLoading(true));
    createClinicBill(clinicId, data)
      .then(() => {
        if (callbackFunc) {
          callbackFunc();
        } else {
          dispatch(getBills(clinicId));
        }
        dispatch(showModal(false));
      })
      .finally(() => dispatch(setLoading(false)))
      .catch(() => dispatch(setLoading(false)));
  };
};

export const createBillPayment = (
  clinicId: number,
  appointmentId: number,
  billId: number,
  data: any,
  callbackFunc: any
): any => {
  return (dispatch) => {
    dispatch(setLoading(true));
    createClinicPaymentBill(clinicId, billId, data)
      .then((response: ShowPaymentResponse) => {
        if (appointmentId) {
          dispatch(getAppointmentBill(clinicId, appointmentId));
        } else {
          dispatch(getBillsPaymentsDetails(clinicId, billId));
          dispatch(getBillsDetails(clinicId, billId));
        }
        if (callbackFunc) {
          callbackFunc(response);
        }
        dispatch(showPaymentsModal(false));
      })
      .finally(() => dispatch(setLoading(false)));
  };
};

export const updateBill = (
  clinicId: number,
  accountId: number,
  data: CreateUpdateBillRequest,
  callbackFunc?: any
): any => {
  return (dispatch) => {
    dispatch(setLoading(true));
    updateClinicBill(clinicId, accountId, data)
      .then((data) => {
        dispatch(getBillsDetails(clinicId, accountId));
        dispatch(showModal(false));
        dispatch(showBillModal(false));
        dispatch(setLoading(false));
        if (callbackFunc) {
          callbackFunc(data);
        }
      })
      .finally(() => dispatch(setLoading(false)))
      .catch(() => dispatch(setLoading(false)));
  };
};

export const removeBill = (clinicId: number, id: number, callbackFn: () => void = null): any => {
  return (dispatch) => {
    dispatch(setLoading(true));
    destroyClinicBill(clinicId, id)
      .then(() => {
        dispatch(getBillsDetails(clinicId, id));
        dispatch(getBillsPaymentsDetails(clinicId, id));
        if (callbackFn) {
          callbackFn();
        }
        dispatch(setLoading(false));
      })
      .finally(() => dispatch(setLoading(false)))
      .catch(() => dispatch(setLoading(false)));
  };
};
