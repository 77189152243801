export enum FORMATS {
  package = 'package',
  unit = 'unit'
}

export enum SALES_LIST_RANGES {
  currentShift = 'current_shift',
  allSales = 'all',
  deletedSales = 'deleted'
}
