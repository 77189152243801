import { AxiosResponse } from 'axios';
import baseClient from '../../../baseClient.ts';
import { generateSearchQuery } from '../../../helpers/commonUtils.ts';
import { IFinancialStats } from 'interfaces/financial-stats/IFinancialStats.ts';
import { ISearchCriteria } from 'interfaces/search-criteria.ts';

function url(clinic_id) {
  return `/v1/clinics/${clinic_id}/stats/financial`;
}

export const indexClinicFinanceStats = async (clinicId: number, searchArray?: ISearchCriteria[]): Promise<IFinancialStats> =>
  baseClient
    .get(`${url(clinicId)}?${generateSearchQuery(searchArray)}`)
    .then((response: AxiosResponse<IFinancialStats>) => {
      return response.data;
    });
