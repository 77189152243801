import './FinancesInfo.scss';
import { Button, Card, Col, DatePicker, Row, Select, Spin } from 'antd';
import useClinicId from 'hooks/useClinicId';
import { useLocale } from 'hooks/useLocale';
import { IAppState } from 'interfaces/app-state';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { closeShift, getOpenedShift, openShift } from 'redux/shifts/actions';
import ShiftStatusCard from '../components/ShiftStatusCard';
import { indexClinicFinanceStats } from 'services/clinic/stats/financial/financialStatsService.ts';
import { localDateTime } from 'helpers/DateTimeUtils.ts';
import dayjs from 'dayjs';
import { ISearchCriteria } from 'interfaces/search-criteria.ts';
import { DATE_TIME_FORMAT } from 'constants/common.tsx';
import { indexClinicShifts } from 'services/clinic/shifts/shiftsService.ts';
import { IndexShiftsResponse, ShiftsData } from 'services/clinic/shifts/models/IndexShiftsResponse.ts';
import { WorkingWeekPicker } from './components/WeekPicker.tsx';
import 'dayjs/locale/uk';
import weekOfYear from 'dayjs/plugin/weekOfYear';
import {
  IAllShiftsProfit,
  IBilledOverPaid,
  IDebt,
  IEmployeesProfit,
  IFinancialStats,
  ILabeledData,
  IShiftProfitByAccounts,
  IShiftProfitLossByCategory
} from 'interfaces/financial-stats/IFinancialStats.ts';
import { AccountsBalancesPieChart } from './components/AccountsBalancesPieChart.tsx';
import { ALLShiftsInOutcomeLineChart } from './components/AllShiftsInOutcomeLineChart.tsx';
import { ShiftInOutcomeByAccountsColumnChart } from './components/ShiftInOutcomeByAccountsColumnChart.tsx';
import { ShiftIncomeByCategoryColumnChart } from './components/ShiftIncomeByCategoryColumnChart.tsx';
import { ShiftBilledAmountByEmployeeColumnChart } from './components/ShiftBilledAmountByEmployeeColumnChart.tsx';
import { ShiftOutcomeByCategoryColumnChart } from './components/ShiftOutcomeByCategoryColumnChart.tsx';
import { AppState } from '../../../redux/store.ts';
import { TotalDebtsColumnChart } from './components/TotalDebtsColumnChart.tsx';
import { BilledOverPaidColumnChart } from './components/BilledOverPaidColumnChart.tsx';

dayjs.extend(weekOfYear);
dayjs.locale('uk');

const { RangePicker } = DatePicker;

interface ILocalizedSelectOption {
  value: string;
  ukUA: string;
  enGB: string;
}

const filterTypeOptions: ILocalizedSelectOption[] = [
  {
    value: 'by_date_range',
    ukUA: 'За період',
    enGB: 'By date range'
  },
  {
    value: 'by_specific_shift',
    ukUA: 'За зміну',
    enGB: 'By shift'
  }
];

const FinancesInfo = (): JSX.Element => {
  const clinicId = useClinicId();
  const dispatch = useDispatch();
  const currencies = useLocale('private.currencies').labels;
  const shifts = useSelector<IAppState, any>((state) => state.shifts);
  const shiftsLoading = useSelector<IAppState, any>((state) => state.shifts.loading);
  const openedShift = shifts?.openedShift;
  const locale = useLocale('private.finances.shifts');
  const localeCode: string = useSelector<AppState, string>(({ localeCode }) => localeCode);

  const [stats, setStats] = useState<IFinancialStats>(null);
  const [dataLoading, setDataLoading] = useState(true);
  const [timeFrom, setTimeFrom] = useState<string>(null);
  const [timeTo, setTimeTo] = useState<string>(null);
  const [filterType, setFilterType] = useState('by_date_range');
  const [shiftsByRange, setShiftsByRange] = useState<ShiftsData[]>([]);
  const [dateRange, setDateRange] = useState<[dayjs.Dayjs, dayjs.Dayjs] | null>([null, null]);
  const [weekRange, setWeekRange] = useState<[dayjs.Dayjs, dayjs.Dayjs] | null>(null);
  const [lineDatePeriod, setLineDatePeriod] = useState('year');

  useEffect((): void => {
    dispatch(getOpenedShift(clinicId));
  }, [clinicId]);

  useEffect((): void => {
    if (!!openedShift) {
      const timeFromUTC = dayjs(openedShift?.opened_at).utc().format(DATE_TIME_FORMAT);
      const timeToUTC = dayjs(openedShift?.closed_at).utc().format(DATE_TIME_FORMAT);
      const timeFromLocal = dayjs(openedShift?.opened_at).local();
      const timeToLocal = dayjs(openedShift?.closed_at).local();
      setTimeFrom(timeFromUTC);
      if (openedShift?.closed_at) setTimeTo(timeToUTC);
      setDateRange([timeFromLocal, openedShift?.closed_at ? timeToLocal : null]);
      onShitsRangeChange([timeFromLocal, openedShift?.closed_at ? timeToLocal : null]);
    }
  }, [openedShift]);

  useEffect(() => {
    if (!!timeFrom || !!timeTo) getStats(timeFrom, timeTo);
  }, [timeFrom, timeTo, lineDatePeriod]);

  useEffect(() => {
    if (weekRange) onShitsRangeChange(weekRange);
  }, [weekRange]);

  useEffect((): void => {
    if (shiftsLoading && !dataLoading) {
      setDataLoading(true);
    } else if (!shiftsLoading && dataLoading) {
      setDataLoading(false);
    }
  }, [shiftsLoading, dataLoading]);

  useEffect(() => {
    return () => setDataLoading(true);
  }, []);

  const onDateRangeChange = (dates: [dayjs.Dayjs, dayjs.Dayjs] | null) => {
    const timeFromUTC = dates[0]?.utc().format(DATE_TIME_FORMAT);
    const timeToUTC = dates[1]?.utc().format(DATE_TIME_FORMAT);
    setDateRange(dates);
    setTimeFrom(timeFromUTC);
    setTimeTo(timeToUTC);
  };

  const backendFilter = (name: string, value: string): ISearchCriteria => (
    { name, value }
  );

  const timeFromBEFilter = (timeFrom: string): ISearchCriteria =>
    backendFilter('time_from', timeFrom);
  const timeToBEFilter = (timeFrom: string): ISearchCriteria =>
    backendFilter('time_to', timeFrom);
  const datePeriodBEFilter = (datePeriod: string): ISearchCriteria =>
    backendFilter('date_period', datePeriod);

  const getStats = (timeFrom: string, timeTo: string) => {
    const timeFromFilter: ISearchCriteria = timeFromBEFilter(timeFrom);
    const timeToFilter: ISearchCriteria = timeToBEFilter(timeTo);
    const searchArray: ISearchCriteria[] = [datePeriodBEFilter(lineDatePeriod)];
    if (timeFrom) searchArray.push(timeFromFilter);
    if (timeTo) searchArray.push(timeToFilter);

    indexClinicFinanceStats(clinicId, searchArray).then((response: IFinancialStats): void => {
      const updatedResponse = remapBEResponse(response);
      setStats(updatedResponse);
    });
  };

  const remapBEResponse = (response: IFinancialStats): IFinancialStats => {
    response.all_shifts_profit =
      remapAllShiftsProfit(response.all_shifts_profit);
    response.shift_profit_by_accounts =
      addAmountLabel<IShiftProfitByAccounts>(response.shift_profit_by_accounts);
    response.shift_profit_by_category =
      addAmountLabel<IShiftProfitLossByCategory>(response.shift_profit_by_category);
    response.shift_loss_by_category =
      addAmountLabel<IShiftProfitLossByCategory>(response.shift_loss_by_category);
    response.employees_profit =
      addAmountLabel<IEmployeesProfit>(response.employees_profit);
    response.total_debts = addAmountLabel<IDebt>(response.total_debts);
    response.billed_over_paid = addAmountLabel<IBilledOverPaid>(response.billed_over_paid);
    return response;
  };

  const remapAllShiftsProfit = (all_shifts_profit: IAllShiftsProfit[]): IAllShiftsProfit[] => (
    all_shifts_profit
      .map((item) => (
        {
          ...item,
          shift_opened_at: selectDateFormat(item.shift_opened_at),
          label: `${item.amount} ${currencies.uah}`
        }
      )));

  const selectDateFormat = (date: string): string => {
    switch (lineDatePeriod) {
      case 'week':
        return localDateTime(date);
      case 'month':
        return dayjs(date).week().toString();
      case 'year':
        return localDateTime(date, 'MMMM');
      default:
        return 'YYYY';
    }
  };

  const addAmountLabel = <T extends ILabeledData>(array: T[]): T[] => (
    array
      .map((item) => (
        {
          ...item,
          label: `${item.amount} ${currencies.uah}`
        }
      )));

  const onOpenShiftClick = (): void => {
    dispatch(openShift(clinicId));
  };

  const onCloseShiftClick = (): void => {
    dispatch(closeShift(clinicId, openedShift.id));
  };

  const onFilterTypeSelect = (value: string) => {
    setFilterType(value);
  };

  const onShitsRangeChange = (dates: [dayjs.Dayjs, dayjs.Dayjs]) => {
    const timeFromUTC: string = dates[0]?.utc().format(DATE_TIME_FORMAT);
    const timeToUTC: string = dates[1]?.utc().format(DATE_TIME_FORMAT);
    const timeToFilter: ISearchCriteria = timeToBEFilter(timeToUTC);
    const timeFromFilter: ISearchCriteria = timeFromBEFilter(timeFromUTC);
    const searchArray: ISearchCriteria[] = [datePeriodBEFilter(lineDatePeriod)];
    if (timeFromUTC) searchArray.push(timeFromFilter);
    if (timeToUTC) searchArray.push(timeToFilter);
    indexClinicShifts(clinicId, 0, searchArray).then((response: IndexShiftsResponse) => {
      setShiftsByRange(response.data);
    });
  };

  const onShiftSelect = (value: number): void => {
    const shift: ShiftsData = shiftsByRange.find((shift: ShiftsData): boolean => shift.id === value);
    const timeFromLocal: dayjs.Dayjs = dayjs(shift.opened_at).local();
    const timeToLocal: dayjs.Dayjs = shift.closed_at ? dayjs(shift.closed_at).local() : null;

    onDateRangeChange([timeFromLocal, timeToLocal]);
  };

  function countUnique<T, K extends keyof T>(array: T[], field: K): number {
    if (!array) return 0;

    return new Set(array.map(item => item[field])).size;
  }

  const convertShiftSelectLabel = (item: ShiftsData): string => {
    const displayFormat: string = 'dddd HH:mm';
    const dateTimeFrom: string = dayjs(item.opened_at).local().format(displayFormat);
    const dateTimeTo: string = item.closed_at ? dayjs(item.closed_at).local().format(displayFormat) : '';
    return `${dateTimeFrom} - ${dateTimeTo}`;
  };

  const column_charts_col_config = <T, K extends keyof T>(array: T[], field: K) => {
    const columnCount = countUnique(array, field);
    const dynamicWidth =  Math.max((columnCount * 3), 5);
    return {
      span: dynamicWidth,
      xs: 24,
      md: 12,
      xl: dynamicWidth,
      xxl: dynamicWidth,
      style: {
        flex: '1 1 100%',
        minWidth: Math.max((columnCount * 5 * 30), 300)
      }
    };
  };

  const shift_main_stats_col_config = {
    span: 12,
    xs: 24,
    sm: 24,
    md: 24,
    xl: 12,
    xxl: 8
  };

  const all_balances_coll_configs = <T, K extends keyof T>(array: T[], field: K) => {
    const columnCount = countUnique(array, field);
    return {
      span: columnCount * 2,
      xs: 24,
      sm: 24,
      md: 24,
      xl: columnCount * 2,
      xxl: columnCount * 2
    };
  };

  return (
    <Spin spinning={dataLoading}>
      <Row id='finances-dashboard' style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
        <Col {...shift_main_stats_col_config}>
          <Card className='ant-card-bordered shift-details-card with-shadow'>
            <ShiftStatusCard shift={openedShift} />
            <Row id='change-status' justify={'end'} style={{ marginTop: '20px' }}>
              {openedShift?.open ? (
                <Button type={'primary'} id='close-shift' onClick={onCloseShiftClick}>
                  {locale.labels.closeShit}
                </Button>
              ) : (
                <Button type={'primary'} id='open-shift' onClick={onOpenShiftClick}>
                  {locale.labels.openShift}
                </Button>
              )}
            </Row>
          </Card>
        </Col>
        <Col {...all_balances_coll_configs(stats?.accounts_balances?.data, 'account_name')}>
          <AccountsBalancesPieChart
            accountsBalances={stats?.accounts_balances}
            locale={locale}
          />
        </Col>
      </Row>
      <Row>
        <Col span={12} xs={24} sm={24} md={24} xl={24} xxl={8}>
          <Row style={{ display: 'flex', flexDirection: 'column' }}>
            <Col>
              <span
                onClick={() => setLineDatePeriod('week')}
                className='ant-btn ant-btn-primary ant-btn-sm'
                style={{ width: '60px' }}
              >
                   {locale.labels.week}
              </span>
              <span
                onClick={() => setLineDatePeriod('month')}
                className='ant-btn ant-btn-primary ant-btn-sm'
                style={{ width: '60px', marginLeft: '2px' }}
              >
                   {locale.labels.month}
              </span>
              <span
                onClick={() => setLineDatePeriod('year')}
                className='ant-btn ant-btn-primary ant-btn-sm'
                style={{ width: '60px', marginLeft: '2px' }}
              >
                   {locale.labels.year}
              </span>
            </Col>
            <Col style={{ width: '100%' }}>
              <ALLShiftsInOutcomeLineChart
                all_shifts_profit={stats?.all_shifts_profit}
                locale={locale}
              />
            </Col>
          </Row>
        </Col>
        {stats?.total_debts?.length > 0 &&
          <Col {...column_charts_col_config(stats?.total_debts, 'type')}>
            <TotalDebtsColumnChart total_debts={stats?.total_debts} locale={locale} />
          </Col>
        }
      </Row>
      <Row style={{ display: 'flex', flexDirection: 'row' }}>
        <Col span={6} xs={24} sm={24} md={6} xl={6} xxl={4} className={'inputs_col_with_margins'}>
          <Select
            value={filterType}
            onSelect={onFilterTypeSelect}
            options={filterTypeOptions.map((item: ILocalizedSelectOption) => ({
              value: item.value,
              label: item[localeCode]
            }))}
          />
        </Col>
        {filterType === 'by_specific_shift' &&
          <>
            <Col span={8} xs={24} sm={24} md={8} xl={8} xxl={8} className={'inputs_col_with_margins'}>
              <WorkingWeekPicker
                weekRange={weekRange}
                setWeekRange={setWeekRange}
                locale={locale}
              />
            </Col>
            <Col span={8} xs={24} sm={24} md={8} xl={8} xxl={8} className={'inputs_col_with_margins'}>
              <Select
                placeholder={locale.placeholders.selectWorkingShift}
                onSelect={onShiftSelect}
                options={shiftsByRange.map((item) => ({
                  value: item.id,
                  label: convertShiftSelectLabel(item)
                }))}
              />
            </Col>
          </>}
        {filterType === 'by_date_range' &&
          <Col span={12} xs={24} sm={24} md={12} xl={10} xxl={8} className={'inputs_col_with_margins'}>
            <RangePicker value={dateRange} showTime onChange={onDateRangeChange} />
          </Col>}
      </Row>
      <Row>
        {stats?.shift_profit_by_accounts?.length > 0 &&
          <Col {...column_charts_col_config(stats?.shift_profit_by_accounts, 'account_name')}>
            <ShiftInOutcomeByAccountsColumnChart
              shift_profit_by_accounts={stats?.shift_profit_by_accounts}
              locale={locale}
            />
          </Col>
        }
        {stats?.shift_profit_by_category?.length > 0 &&
          <Col {...column_charts_col_config(stats?.shift_profit_by_category, 'category')}>
            <ShiftIncomeByCategoryColumnChart
              shift_profit_by_category={stats?.shift_profit_by_category}
              locale={locale}
            />
          </Col>
        }
        {stats?.shift_loss_by_category?.length > 0 &&
          <Col {...column_charts_col_config(stats?.shift_loss_by_category, 'category')}>
            <ShiftOutcomeByCategoryColumnChart
              shift_loss_by_category={stats?.shift_loss_by_category}
              locale={locale}
            />
          </Col>
        }
        {stats?.employees_profit?.length > 0 &&
          <Col {...column_charts_col_config(stats?.employees_profit, 'user_name')}>
            <ShiftBilledAmountByEmployeeColumnChart
              employees_profit={stats?.employees_profit}
              locale={locale}
            />
          </Col>
        }
        {stats?.billed_over_paid?.length > 0 &&
          <Col {...column_charts_col_config(stats?.billed_over_paid, 'name')}>
            <BilledOverPaidColumnChart billed_over_paid={stats?.billed_over_paid} locale={locale} />
          </Col>
        }
      </Row>
    </Spin>
  );
};

export default FinancesInfo;
