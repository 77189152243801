import '../scss/StockDetails.scss';

import { Button, Card, Col, List, Menu, MenuProps, Row, Spin } from 'antd';
import ViewDetailsButtonsGroup from 'components/ViewDetailsButtonsGroup';
import dayjs from 'dayjs';
import customParseFormat from 'dayjs/plugin/customParseFormat';
import { useI18n } from 'hooks/usei18n';
import { useLocale } from 'hooks/useLocale';
import { useUserAccess } from 'hooks/useUserAccess';
import { stockStatuses } from 'i18n/stockStatuses';
import { IAppState } from 'interfaces/app-state';
import { PrintPricesModal } from 'layout/modals/printPrices/PrintPricesModal';
import React, { useEffect, useMemo, useState } from 'react';
import Barcode from 'react-barcode';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { getStockById } from 'redux/inventory/stock/actions';

import getStatus from '../helpers/TagSelector';
import { AddItemsToStockModal } from '../modals/AddItemsToStockModal';
import { EditStockItemModal } from '../modals/EditStockItemModal';
import { ReassignLocationModal } from '../modals/ReassignLocationModal';
import { ReassignPriceModal } from '../modals/ReassignPriceModal';
import { useGetResidual } from '../utils/useGetResidual';
import { StockDetailsHeader } from './StockDetailsHeader';
import { StockHistoryTab } from './StockHistoryTab';

dayjs.extend(customParseFormat);

const StockDetails = (): JSX.Element => {
  const dispatch = useDispatch();
  const { clinicId, stockId } = useParams();
  const userAccess = useUserAccess();
  const locale = useLocale('private.inventory.stock');
  const currencies = useLocale('private.currencies').labels;
  const statuses = useI18n(stockStatuses);
  const selectedStock = useSelector(({ stock }: IAppState) => stock.selectedStock);
  const { getResidual, unitOfMeasurement, packagingFormat, noPackageFormat } =
    useGetResidual(selectedStock);
  const formatLabel = noPackageFormat
    ? `(${unitOfMeasurement || locale.labels.psc})`
    : `(${packagingFormat}/${unitOfMeasurement})`;

  const filteredLocations = selectedStock?.locations.filter((el) => !el.deleted && el.residual);
  const filteredPrices = selectedStock?.prices.filter((el) => !el.deleted && el.residual);

  const loading = useSelector(({ stock }: IAppState) => stock.loading);

  const [currentTab, setCurrentTab] = useState('general');
  const [showAddItemsToStockModal, setShowAddItemsToStockModal] = useState<boolean>(false);
  const [showEditStockItemModal, setShowEditStockItemModal] = useState<boolean>(false);
  const [showReassignLocationsModal, setShowReassignLocationsModal] = useState<boolean>(false);
  const [showReassignPriceModal, setShowReassignPriceModal] = useState<boolean>(false);
  const [showPrintPricesModal, setShowPrintPricesModal] = useState(false);

  useEffect((): void => {
    dispatch(getStockById(+clinicId, +stockId));
  }, []);

  const getStockAvailability = (
    quantity: number,
    reorder_point: number
  ): { value: string; label: string } => {
    if (quantity > reorder_point) {
      return { value: 'in_stock', label: statuses['in_stock'] };
    } else if (quantity > 0) {
      return { value: 'critical_level', label: statuses['critical_level'] };
    }
    return { value: 'out_of_stock', label: statuses['out_of_stock'] };
  };

  const items: MenuProps['items'] = [
    {
      label: locale.tabs.general,
      key: 'general'
    },
    {
      label: locale.tabs.history,
      key: 'history'
    }
  ];

  const changeTab: MenuProps['onClick'] = ({ key }: { key: string }): void => {
    setCurrentTab(key);
  };

  const stockLocations = useMemo(
    (): string[] =>
      (filteredLocations || [])
        .sort((a) => (a.name === 'unassigned' ? 1 : -1))
        .map(
          (el) =>
            `${el.name === 'unassigned' ? locale.labels.unassigned : el.name}: ${getResidual(
              el.residual
            )}`
        ),
    [selectedStock?.locations]
  );

  const getStockPrices = (): string[] =>
    (filteredPrices || [])
      .sort((el) => (el.type === 'default' ? -1 : 1))
      .map((el) => {
        const isDefaultPrice = el.type === 'default' ? `(${locale.labels.default})` : '';
        const prices = noPackageFormat
          ? el.price_per_unit.toFixed(2)
          : `${el.price_per_package}/${el.price_per_unit.toFixed(2)}`;

        return `${prices} ${currencies.uah} ${isDefaultPrice}: ${getResidual(el.residual)}`;
      });

  return (
    <Spin spinning={loading} className='stock-details-page-spin-container'>
      {!!selectedStock && (
        <>
          <Row className='patient-details'>
            <Col span={24} md={11} lg={8} style={{ padding: '0 12px' }}>
              <Card className='ant-card-bordered personal-info-card with-shadow patient-info-container stock-details-info-container'>
                <Row justify='center'>
                  <div className={'name-statuses-form-header'}>
                    <h4 id='stock-name'>{selectedStock.name}</h4>
                    {getStatus(
                      getStockAvailability(selectedStock.residual, selectedStock.reorder_point)
                    )}
                  </div>
                  <div style={{ width: '100%', display: 'flex', justifyContent: 'center' }}>
                    <span style={{ fontWeight: 600 }}>{locale.labels.sku}:&nbsp;</span>
                    <span id='sku'>{selectedStock.sku || '-'}</span>
                  </div>
                  <div className='stock-barcode'>
                    {selectedStock.barcode && <Barcode value={selectedStock.barcode} />}
                  </div>
                  <div
                    style={{
                      display: 'flex',
                      width: '100%',
                      justifyContent: 'flex-start',
                      flexDirection: 'column',
                      marginBottom: 20,
                      marginTop: 20
                    }}
                  >
                    <List
                      id={'stock-quantity'}
                      header={`${locale.labels.quantity}`}
                      style={{ marginBottom: 20, fontSize: 14 }}
                      dataSource={[getResidual(selectedStock?.residual)]}
                      bordered
                      renderItem={(item) => <List.Item style={{ fontSize: 14 }}>{item}</List.Item>}
                    />
                    {!!filteredPrices?.length && (
                      <List
                        id={'stock-prices'}
                        header={
                          <div className='reassign-header'>
                            <span>{`${locale.labels.prices} ${formatLabel}`}</span>
                            <Row>
                              <Button
                                type='primary'
                                onClick={() => {
                                  setShowReassignPriceModal(true);
                                }}
                                style={{ borderRadius: 30, width: 44, height: 30 }}
                              >
                                <span className='icofont icofont-edit-alt' />
                              </Button>
                              <Button
                                type='primary'
                                onClick={() => {
                                  setShowPrintPricesModal(true);
                                }}
                                style={{ borderRadius: 30, width: 44, height: 30 }}
                              >
                                <span className='icofont icofont-print' />
                              </Button>
                            </Row>
                          </div>
                        }
                        style={{ marginBottom: 20, fontSize: 14 }}
                        bordered
                        dataSource={getStockPrices()}
                        renderItem={(item) => (
                          <List.Item style={{ fontSize: 14 }}>{item}</List.Item>
                        )}
                      />
                    )}
                    {!!filteredLocations?.length && (
                      <List
                        id={'stock-locations'}
                        header={
                          <div className='reassign-header'>
                            <span>{locale.labels.locations}</span>
                            <Button
                              type='primary'
                              onClick={() => {
                                setShowReassignLocationsModal(true);
                              }}
                              style={{ borderRadius: 30, width: 44, height: 30 }}
                            >
                              <span className='icofont icofont-loop' />
                            </Button>
                          </div>
                        }
                        style={{ marginBottom: 20 }}
                        bordered
                        dataSource={stockLocations}
                        renderItem={(item) => (
                          <List.Item style={{ fontSize: 14 }}>{item}</List.Item>
                        )}
                      />
                    )}
                  </div>
                  <Button
                    type='primary'
                    style={{ borderRadius: 30 }}
                    onClick={() => setShowAddItemsToStockModal(true)}
                  >
                    <span
                      className='icofont icofont-plus mr-2 sticky-btn-icon'
                      style={{ fontSize: '1.3em' }}
                    />
                    <span>{locale.buttons.add}</span>
                  </Button>
                </Row>
              </Card>
            </Col>
            <Col span={24} md={13} lg={16} style={{ padding: '0 12px' }}>
              <Card className='ant-card-bordered personal-info-card with-shadow'>
                <Menu
                  onClick={changeTab}
                  selectedKeys={[currentTab]}
                  mode='horizontal'
                  items={items}
                  style={{ marginBottom: 20 }}
                />
                {currentTab === 'general' && (
                  <div className='stock-row-container__form' style={{ padding: '0 15px' }}>
                    <StockDetailsHeader selectedStock={selectedStock} locale={locale} />
                    <div
                      style={{
                        visibility: !showEditStockItemModal ? 'visible' : 'hidden',
                        padding: 10
                      }}
                    >
                      <ViewDetailsButtonsGroup
                        hideCancelBtn
                        fixed
                        accessGroup={userAccess.accesses.stock}
                        editMode={false}
                        onEditClick={() => setShowEditStockItemModal(true)}
                        hideDeleteBtn
                      />
                    </div>
                  </div>
                )}
                {currentTab === 'history' && <StockHistoryTab stockId={+stockId} locale={locale} selectedStock={selectedStock}/>}
              </Card>
            </Col>
          </Row>
          {showReassignPriceModal && (
            <ReassignPriceModal
              locale={locale}
              stockName={selectedStock.name}
              setShowReassignPriceModal={setShowReassignPriceModal}
            />
          )}
          {showReassignLocationsModal && (
            <ReassignLocationModal
              locale={locale}
              setShowReassignLocationsModal={setShowReassignLocationsModal}
            />
          )}
          {showEditStockItemModal && (
            <EditStockItemModal
              locale={locale}
              selectedStock={selectedStock}
              setShowEditStockItemModal={setShowEditStockItemModal}
            />
          )}
          {showAddItemsToStockModal && (
            <AddItemsToStockModal
              accessGroup={userAccess.accesses.stock}
              locale={locale}
              selectedStock={selectedStock}
              setShowAddItemsToStockModal={setShowAddItemsToStockModal}
            />
          )}
          {showPrintPricesModal && <PrintPricesModal setShowPrintPricesModal={setShowPrintPricesModal} stock={selectedStock} prices={filteredPrices} />}
        </>
      )}
    </Spin>
  );
};

export default StockDetails;
