import { EditOutlined } from '@ant-design/icons';
import { Col, Form, Input, notification, Row, Select, Spin, Typography } from 'antd';
import ConfirmCancelModal from 'components/ConfirmCancelModal';
import PhoneFieldFormItem from 'components/PhoneFieldFormItem';
import { TextEditor } from 'components/TextEditor';
import ViewDetailsButtonsGroup from 'components/ViewDetailsButtonsGroup';
import VLXModal from 'components/VLXModal';
import { getIcon, socialMediaLinks } from 'constants/dictionary/default/socialMediaOptions';
import { useI18n } from 'hooks/usei18n';
import { useLocale } from 'hooks/useLocale';
import { useUserAccess } from 'hooks/useUserAccess';
import { companyTypes } from 'i18n/companyTypesSelect';
import { IClinicPayload } from 'interfaces/clinic';
import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { editClinic, showModal } from 'redux/clinic/actions';
import { ClinicState } from 'redux/clinic/reducer';
import { patternRule, PATTERNS, requiredRule } from 'utils/form-rules';
import { trimSocialLinks } from 'utils/trimSocialLinks';

import { AddButton } from './components/AddButton';
import { RemoveButton } from './components/RemoveButton';

const { Option } = Select;

type Props = {
  clinic: ClinicState;
  loading: boolean;
};

export const ClinicInfoForm = ({ clinic, loading }: Props): JSX.Element => {
  const dispatch = useDispatch();
  const [form] = Form.useForm();
  const { accesses } = useUserAccess();

  const companyTypesOptions = useI18n(companyTypes);
  const locale = useLocale('private.administration.general');
  const [showCancelModal, setShowCancelModal] = useState<boolean>(false);

  useEffect(() => form.resetFields(), [clinic]);

  const onConfirmCancelClick = (): void => {
    form.resetFields();
    dispatch(showModal(false));
    setShowCancelModal(false);
  };

  const onSubmit = (values: IClinicPayload): void => {
    const payload = {
      ...values,
      social_media_links: trimSocialLinks(values.social_media_links),
      additional_contacts: values.additional_contacts
        ? JSON.stringify(values.additional_contacts)
        : null
    };
    dispatch(editClinic(clinic.id, payload));
    notification.success({ message: locale.messages.dataSavedSuccessfully });
  };

  const editButton = (): JSX.Element | null => {
    if (!accesses.clinics.edit) return null;
    return <EditOutlined style={{ fontSize: '20px' }} onClick={() => dispatch(showModal(true))} />;
  };

  const socialMediaList = (): JSX.Element[] =>
    socialMediaLinks.map((link, index) => (
      <Option key={index} value={link.value}>
        {link.value}
        {getIcon(link.key === 'other' ? 'page' : link.key, 16)}
      </Option>
    ));

  return (
    <>
      {editButton()}

      <VLXModal
        title={locale.labels.updateNewClinic}
        centered
        form={form}
        open={clinic.showModal}
        onModalClose={() => dispatch(showModal(false))}
      >
        <Spin spinning={loading}>
          <Form onFinish={onSubmit} autoComplete='off' layout='vertical' form={form}>
            <Form.Item
              label={locale.labels.clinicName}
              name='name'
              rules={[requiredRule(locale.messages.nameEmpty)]}
              initialValue={clinic.name}
            >
              <Input />
            </Form.Item>
            <Form.Item
              label={locale.labels.type}
              name='company_type'
              initialValue={clinic.company_type}
            >
              <Select
                options={Object.keys(companyTypesOptions).map((value) => ({
                  value,
                  label: companyTypesOptions[value]
                }))}
              />
            </Form.Item>
            <PhoneFieldFormItem
              formItemPhonePrefix={['country_code']}
              formItemPhoneNumber={['phone_number']}
              initialValues={clinic}
            />
            <Form.Item
              label={locale.labels.eMail}
              name='email'
              rules={[
                requiredRule(locale.messages.emailEmpty),
                patternRule(PATTERNS.EMAIL, locale.messages.emailNotValid)
              ]}
              initialValue={clinic.email}
            >
              <Input />
            </Form.Item>
            <Form.Item
              label={locale.labels.address}
              name='location'
              rules={[requiredRule(locale.messages.addressEmpty)]}
              initialValue={clinic.location}
            >
              <Input />
            </Form.Item>
            <TextEditor
              label={locale.labels.workingHours}
              name='working_hours'
              initialValue={clinic.working_hours}
            />
            <TextEditor
              label={locale.labels.description}
              name='description'
              initialValue={clinic.description}
            />
            <Typography.Title level={5}>{locale.labels.socialMedia}</Typography.Title>
            <Form.List
              name='social_media_links'
              initialValue={clinic.social_media_links ? JSON.parse(clinic.social_media_links) : []}
            >
              {(fields, { add, remove }) => (
                <>
                  {fields?.map(({ key, name, ...restField }) => (
                    <Row key={key} gutter={[24, 10]}>
                      <Col span={11} md={11} sm={24} xs={24}>
                        <Form.Item
                          {...restField}
                          name={[name, 'type']}
                          rules={[requiredRule(locale.messages.selectSocialNetwork)]}
                        >
                          <Select
                            placeholder={locale.placeholders.selectSocialMedia}
                            filterOption={(input, option) =>
                              option?.key.toLowerCase().indexOf(input.toLowerCase()) >= 0
                            }
                            showSearch
                          >
                            {socialMediaList()}
                          </Select>
                        </Form.Item>
                      </Col>
                      <Col span={11} md={11} sm={20} xs={20}>
                        <Form.Item
                          {...restField}
                          name={[name, 'description']}
                          rules={[
                            requiredRule(locale.messages.specifyLink),
                            patternRule(PATTERNS.SOCIAL_LINK, locale.errors.invalidSocial)
                          ]}
                        >
                          <Input placeholder={locale.placeholders.linkToProfile} />
                        </Form.Item>
                      </Col>
                      <RemoveButton remove={() => remove(name)} />
                    </Row>
                  ))}
                  <AddButton add={add} fields={fields} label={locale.buttons.addSocialLinks} />
                </>
              )}
            </Form.List>
            <Row>
              <Typography.Title level={5}>{locale.labels.additionalContacts}</Typography.Title>
            </Row>
            <Form.List
              name='additional_contacts'
              initialValue={
                clinic.additional_contacts ? JSON.parse(clinic.additional_contacts) : []
              }
            >
              {(fields, { add, remove }) => (
                <>
                  {fields?.map(({ key, name, ...restField }) => (
                    <Row key={key} gutter={[24, 10]}>
                      <Col span={11} md={11} sm={24} xs={24}>
                        <PhoneFieldFormItem
                          formItemPhonePrefix={[name, 'country_code']}
                          formItemPhoneNumber={[name, 'phone_number']}
                          hideLabel
                        />
                      </Col>
                      <Col span={11} md={11} sm={20} xs={20}>
                        <Form.Item
                          {...restField}
                          name={[name, 'description']}
                          rules={[requiredRule(locale.messages.specifyDescription)]}
                        >
                          <Input placeholder={locale.placeholders.description} />
                        </Form.Item>
                      </Col>
                      <RemoveButton remove={() => remove(name)} />
                    </Row>
                  ))}
                  <AddButton
                    add={add}
                    fields={fields}
                    label={locale.buttons.addAdditionalContacts}
                  />
                </>
              )}
            </Form.List>
            <ViewDetailsButtonsGroup
              fixed
              accessGroup={accesses.clinics}
              editMode
              hideDeleteBtn
              onCancelClick={() => {
                if (form.isFieldsTouched()) {
                  setShowCancelModal(true);
                } else {
                  dispatch(showModal(false));
                }
              }}
              onDeleteClick={() => {
                setShowCancelModal(false);
              }}
            />
          </Form>
        </Spin>
        <ConfirmCancelModal
          open={showCancelModal}
          onOk={onConfirmCancelClick}
          onCancel={() => setShowCancelModal(false)}
        />
      </VLXModal>
    </>
  );
};
