import '../scss/ViewEditInvoice.scss';

import { Spin } from 'antd';
import ConfirmDeleteModal from 'components/ConfirmDeleteModal';
import { StatusTag } from 'components/StatusTag.tsx';
import dayjs from 'dayjs';
import customParseFormat from 'dayjs/plugin/customParseFormat';
import { useLocale } from 'hooks/useLocale';
import { useUserAccess } from 'hooks/useUserAccess';
import { IAppState } from 'interfaces/app-state';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import { getBillsDetails } from 'redux/finances/bills/actions.ts';
import { getInvoiceById, getInvoiceStockItemsById, removeInvoice } from 'redux/inventory/invoice/actions';
import { getOpenedShift } from 'redux/shifts/actions.ts';

import { AddItemsToStockModal } from '../../stock/modals/AddItemsToStockModal';
import EditInvoiceModal from '../EditInvoiceModal';
import { InvoiceItemsTable } from '../InvoiceItemsTable';
import { InvoiceDetailsHeader } from './InvoiceDetailsHeader';

dayjs.extend(customParseFormat);

const InvoiceDetails = (): JSX.Element => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { clinicId, invoiceId }: any = useParams();
  const userAccess = useUserAccess();
  const currencies = useLocale('private.currencies').labels;
  const locale = useLocale('private.inventory.invoices');

  const selectedInvoice = useSelector(({ invoices }: IAppState) => invoices.selectedInvoice);
  const bill = useSelector(({ bills }: IAppState) => bills.selectedBill);
  const bills = useSelector(({ bills }: IAppState) => bills);
  const loading = useSelector(({ invoices }: IAppState) => invoices.loading);

  const [showEditInvoiceModal, setShowEditInvoiceModal] = useState<boolean>(false);
  const [showDeleteModal, setShowDeleteModal] = useState<boolean>(false);
  const [showAddItemsToStockModal, setShowAddItemsToStockModal] = useState<boolean>(false);

  const editingDisabled = bill?.payment_status === 'paid' || bill?.payment_status === 'partially_paid';

  useEffect((): void => {
    dispatch(getInvoiceById(clinicId, invoiceId));
    dispatch(getInvoiceStockItemsById(clinicId, invoiceId));
    dispatch(getOpenedShift(+clinicId));
  }, []);

  useEffect((): void => {
    if (selectedInvoice) dispatch(getBillsDetails(clinicId, selectedInvoice?.bill_id));
  }, [selectedInvoice]);

  const onConfirmDeleteClick = (): void => {
    dispatch(removeInvoice(clinicId, invoiceId, locale));
    navigate(`/clinic/${clinicId}/inventory/invoices`);
  };

  return (
    <Spin spinning={loading || bills.loading} className='invoice-details-page-spin-container'>
      {(!!selectedInvoice && !!bill) && (
        <div style={{ paddingLeft: '20px' }}>
          <div className={'name-statuses-form-header'}>
            <h4 style={{ marginRight: '8px' }} id='invoice-number'>
              {selectedInvoice.invoice_number}
            </h4>
            {!!bill?.payment_status && <StatusTag status={bill?.payment_status} />}
          </div>
          <div className='invoice-row-container__form' style={{ marginBottom: 10 }}>
            <InvoiceDetailsHeader
              setShowEditInvoiceModal={setShowEditInvoiceModal}
              selectedInvoice={selectedInvoice}
              clinicId={clinicId}
              currencies={currencies}
              locale={locale}
              editingDisabled={editingDisabled}
            />
          </div>
          <div className='invoice-row-container__table'>
            <InvoiceItemsTable editingDisabled={editingDisabled} setShowAddItemsToStockModal={setShowAddItemsToStockModal}/>
          </div>
          {showEditInvoiceModal && (
            <EditInvoiceModal
              locale={locale}
              selectedInvoice={selectedInvoice}
              invoiceId={invoiceId}
              setShowEditInvoiceModal={setShowEditInvoiceModal}
              showEditInvoiceModal={showEditInvoiceModal}
            />
          )}
          {showDeleteModal && (
            <ConfirmDeleteModal
              type={'device'}
              open={showDeleteModal}
              onOk={onConfirmDeleteClick}
              onCancel={() => setShowDeleteModal(false)}
            />
          )}
          {showAddItemsToStockModal && (
            <AddItemsToStockModal
              accessGroup={userAccess.accesses.invoices}
              invoiceId={invoiceId}
              locale={locale}
              setShowAddItemsToStockModal={setShowAddItemsToStockModal}
            />
          )}
        </div>
      )}
    </Spin>
  );
};

export default InvoiceDetails;
