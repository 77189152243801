import './PatientList.scss';

import { Pagination, Row, Spin, Table, TableColumnProps } from 'antd';
// import StickyButton from 'components/StickyButton';
import animalsList from 'constants/dictionary/animals';
import { vaccineAntigens } from 'constants/dictionary/default/selectOptions';
import dayjs from 'dayjs';
import { useLocale } from 'hooks/useLocale';
import useLocalizedList from 'hooks/useLocalizedList';
import { IAppState } from 'interfaces/app-state';
import { IVaccinationItemData } from 'interfaces/vaccinationDetails';
import GenerateVaccinationReport from 'layout/modals/generateVaccinationReport/GenerateVaccinationReport';
// import AddVaccination from 'layout/modals/vaccination/AddVaccination';
import React, { useMemo } from 'react';
import { useSelector } from 'react-redux';

export const VaccinationsTab = ({getResults, onPageChange, handleChange}: any): JSX.Element => {
  // const [form] = Form.useForm();

  const localePage = useLocale('private.patients.patient-page');

  const loading = useSelector(({ patients }: IAppState) => patients.loading);
  const {
    vaccinations
  } = useSelector(({ patients }: IAppState) => patients);
  const localeCode = useSelector(({ localeCode }: IAppState) => localeCode);

  // const [showEditItemModal, setShowEditItemModal] = useState<boolean>(false);

  const localizedVaccineAntigens = useLocalizedList(vaccineAntigens);

  const getCoverageCellData = (data: string[]): string | JSX.Element[] => {
    if (data?.length) {
      return data.map((el) => (
        <div key={el}>
          {localizedVaccineAntigens.find((item) => item.value === el).label}
        </div>
      ));
    }

    return '-';
  };

  const getPatientSpecies =  (e: IVaccinationItemData) => {
    const species = animalsList.find((el) => el.value === e.patient_species);
    return  species?.[localeCode];
  };

  const columns = useMemo(
    (): TableColumnProps<IVaccinationItemData>[] => [
      {
        key: 'patient_name',
        title: localePage.labels.patient,
        sorter: (a, b) => null,
        render: (e) => e.patient_name
      },
      {
        key: 'patient_species',
        title: localePage.labels.species,
        sorter: (a, b) => null,
        render: (e) => getPatientSpecies(e)
      },
      {
        key: 'client_name',
        title: localePage.labels.owner,
        sorter: (a, b) => null,
        render: (e) => e.client_name
      },
      {
        key: 'name',
        title: localePage.labels.vaccine,
        sorter: (a, b) => null,
        render: (e) => e.name
      },
      {
        key: 'manufacturer',
        title: localePage.labels.manufacturer,
        sorter: (a, b) => null,
        render: (e) => e.manufacturer
      },
      {
        key: 'coverage',
        title: localePage.labels.coverage,
        sorter: (a, b) => null,
        render: (e) => getCoverageCellData(e.coverage)
      },
      {
        key: 'serial_number',
        title: localePage.labels.serialNumber,
        sorter: (a, b) => null,
        render: (e) => e.serial_number
      },
      {
        key: 'date_of_vaccination',
        title: localePage.labels.dateVaccination,
        sorter: (a, b) => null,
        render: (e) =>
          e.date_of_vaccination ? dayjs(e.date_of_vaccination).format('DD.MM.YYYY') : '-'
      },
      {
        key: 'date_of_next_vaccination',
        title: localePage.labels.dateNextVaccination,
        sorter: (a, b) => null,
        render: (e) =>
          e.date_of_next_vaccination ? dayjs(e.date_of_next_vaccination).format('DD.MM.YYYY') : '-'
      }
    ],
    [vaccinations]
  );

  // const closeVaccinationModal = (): void => {
  //   setShowEditItemModal(false);
  // };

  return (
    <>
      <Spin spinning={loading}>
        <Table
          pagination={false}
          className='accent-header patients-list-table'
          rowKey={(row) => `${row.id}_${crypto.randomUUID()}`}
          dataSource={vaccinations?.data}
          onChange={handleChange}
          columns={columns}
        />
        <Row
          style={{
            justifyContent: 'space-between',
            marginTop: 10,
            alignItems: 'center'
          }}
        >
          <Pagination
            defaultCurrent={1}
            pageSize={20}
            current={vaccinations?.metadata.page}
            total={vaccinations?.metadata.total}
            showSizeChanger={false}
            showLessItems
            onChange={onPageChange}
          />
          {/* <StickyButton
            offsetBottom={10}
            onClick={() => setShowEditItemModal(true)}
            iconElement={
              <span
                className='icofont icofont-plus mr-2 sticky-btn-icon'
                style={{ fontSize: '1.3em' }}
              />
            }
            textElement={localePage.buttons.addVaccination}
          /> */}
        </Row>
      </Spin>
      <GenerateVaccinationReport />
      {/* <AddVaccination // next task
        showModal={showEditItemModal}
        closeVaccinationModal={closeVaccinationModal}
        form={form}
      /> */}
    </>
  );
};
