import { Form } from 'antd';
import ConfirmCancelModal from 'components/ConfirmCancelModal';
import ViewDetailsButtonsGroup from 'components/ViewDetailsButtonsGroup';
import { useLocale } from 'hooks/useLocale';
import { useUserAccess } from 'hooks/useUserAccess';
import React, { useState } from 'react';

import { PrintConfigurationForm } from '../../layout/modals/printConfiguration/PrintConfigurationForm';

export const PrintConfiguration = (): JSX.Element => {
  const userAccess = useUserAccess();
  const [form] = Form.useForm();
  const locale = useLocale('public.modals.printConfiguration');

  const [showCancelModal, setShowCancelModal] = useState<boolean>(false);

  const onConfirmCancelClick = () => {
    form.resetFields();
    setShowCancelModal(false);
  };

  return (
    <>
      <h3>{locale.labels.printConfiguration}</h3>
      <PrintConfigurationForm
        form={form}
      >
        <ViewDetailsButtonsGroup
          fixed
          accessGroup={userAccess.accesses.services}
          editMode
          hideDeleteBtn
          onCancelClick={() => {
            if (form.isFieldsTouched()) {
              setShowCancelModal(true);
            }
          }}
        />
      </PrintConfigurationForm>
      <ConfirmCancelModal
        open={showCancelModal}
        onOk={onConfirmCancelClick}
        onCancel={() => {
          setShowCancelModal(false);
        }}
      />
    </>
  );
};
