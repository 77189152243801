import 'moment/locale/uk';
import './AppointmentsList.scss';

import { Button, Pagination, Popover, Row, Spin, Table, TableColumnProps, Tooltip } from 'antd';
import ListFieldWithTooltip from 'components/ListFieldWithTooltip';
import VLXSearch from 'components/VLXSearch';
import {
  appointmentListStatuses,
  appointmentListStatusesFreemium,
  appointmentStatuses,
  paymentStatuses
} from 'constants/dictionary/default/selectOptions';
import { blacklistedStatus } from 'helpers/ViewClientHelper';
import useClinicId from 'hooks/useClinicId';
import { useLocale } from 'hooks/useLocale';
import useLocalizedList from 'hooks/useLocalizedList';
import { useSearch } from 'hooks/useSearch';
import { IAppState } from 'interfaces/app-state';
import { IAppointment } from 'interfaces/appointment';
import { IPatient } from 'interfaces/patient';
import AddAppointment from 'layout/modals/addAppointment/AddAppointment';
import moment from 'moment';
import React, { useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useNavigate } from 'react-router-dom';
import {
  getAppointments,
  patchAppointmentOnTheList,
  resetAppointments,
  setInitialDataForAppointment,
  setSelectedAppointment,
  setSelectedAppointmentHealthRecords,
  setSelectedAppointmentPatients
} from 'redux/appointments/actions';
import { setSelectedClient } from 'redux/clients/actions';
import { setSelectedPatienVaccinationst } from 'redux/patients/actions';
import { getTime } from 'utils/get-time';

import { IncompleteHealthRecordMarker } from '../../components/IncompleteHealthRecordMarker';
import { useUserAccess } from '../../hooks/useUserAccess';
import { renderAppointmentStatus, renderPaymentStatus } from './AppointmentsBaseHelper';
import CancellationForm from './components/CancellationForm';

const statusPayload = (status: string) => ({
  properties: [
    {
      name: 'status',
      value: status
    }
  ]
});

const AppointmentsList = (): JSX.Element => {
  const dispatch = useDispatch();
  const clinicId = useClinicId();
  const navigate = useNavigate();
  const locale = useLocale('private.appointments.event');
  const localizedStatuses = useLocalizedList(appointmentStatuses);
  const localizedPaymentStatuses = useLocalizedList(paymentStatuses);
  const appModules = useUserAccess().availableModules;
  const localizedAppointmentListStatuses = useLocalizedList(
    appModules.finances ? appointmentListStatuses : appointmentListStatusesFreemium
  );

  const loading = useSelector(({ appointments }: IAppState) => appointments.loading);
  const data = useSelector(({ appointments }: IAppState) => appointments.data);
  const metadata = useSelector(({ appointments }: IAppState) => appointments.metadata);
  const userLocale = useSelector(({ user }: IAppState) => user.locale);

  const [searchItemsArray, setSearchItemsArray] = useState([]);
  const [currentAppointmentId, setCurrentAppointmentId] = useState(null);
  const [showCancellationModal, setShowCancellationModal] = useState(false);

  useEffect((): void => {
    if (userLocale === 'ukUA') {
      moment.locale('uk');
    } else {
      moment.locale('en');
    }
  }, [userLocale]);

  useEffect((): void => {
    dispatch(setSelectedAppointment(null));
    dispatch(setSelectedAppointmentHealthRecords(null));
    dispatch(setSelectedPatienVaccinationst(null));
    dispatch(setSelectedClient(null));
    dispatch(setSelectedAppointmentPatients(null));
  }, [clinicId]);

  useEffect(() => {
    dispatch(setInitialDataForAppointment(null));

    return () => {
      dispatch(resetAppointments());
    };
  }, []);

  const { handleChange, onPageChange, filterResults, getResults } = useSearch({
    action: getAppointments,
    clinicId,
    defaultSortKey: 'created_at',
    defaultSortOrder: 'desc',
    selectOptions: localizedAppointmentListStatuses
  });

  const redirectToAppointment = ({ id }: { id: number }): { onClick: () => void } => ({
    onClick: (): void => {
      navigate(`/clinic/${clinicId}/appointments/${id}`);
    }
  });

  const redirectToClient = (record: IAppointment): string =>
    `/clinic/${clinicId}/clients/${record?.client?.id}`;

  const redirectToPatient = (record: IPatient): string =>
    `/clinic/${clinicId}/patients/${record?.id}`;

  const onStatusChange = (newStatus, appointment): void => {
    dispatch(
      patchAppointmentOnTheList(clinicId, appointment.id, statusPayload(newStatus), getResults)
    );
  };

  const getPatientView = (e: IAppointment): JSX.Element[] | string => {
    if (!e.patients?.length) return '-';

    return e.patients.map((el) => (
      <div key={el.id}>
        <Link className={'custom-links'} to={redirectToPatient(el)}>
          {el.name}
        </Link>
      </div>
    ));
  };

  const StatusTooltip = (appointment: IAppointment): JSX.Element => {
    const title = appointment?.cancellation?.cancelled_by?.title;
    return (
      <div>
        {!!appointment?.cancellation?.reason && (
          <>
            <p style={{ marginBottom: '2px' }}>
              {locale.labels.cancelledBy}: {appointment?.cancellation?.cancelled_by?.name}{' '}
              {title ? `(${title})` : ''}
            </p>
            <p style={{ marginBottom: '2px' }}>
              {locale.labels.reason}: {`${appointment.cancellation.reason}`}
            </p>
          </>
        )}
      </div>
    );
  };

  function canStart(appointment: IAppointment): boolean {
    return appointment.status === 'future';
  }

  function canCancel(appointment: IAppointment): boolean {
    return ['future', 'in_progress'].includes(appointment.status);
  }

  function defineStatus(appointment): JSX.Element {
    if (!appointment.status) return null;

    if (appointment.status === 'cancelled') {
      return (
        <div
          style={{
            display: 'flex',
            alignItems: 'center',
            flexWrap: 'nowrap'
          }}
        >
          {renderAppointmentStatus(appointment.status, localizedStatuses)}
          <Popover
            // onOpenChange={onOpenChange}
            placement='topLeft'
            title={locale.labels.appointmentCancelled}
            content={StatusTooltip(appointment)}
          >
            <span
              className='icofont-info-circle'
              style={{ fontSize: '15px', marginLeft: 6, alignSelf: 'baseline' }}
            />
          </Popover>
        </div>
      );
    }

    if (appointment.status === 'finished' && appModules.finances) {
      return renderPaymentStatus(appointment.payment_status, localizedPaymentStatuses);
    }

    return renderAppointmentStatus(appointment.status, localizedStatuses);
  }

  const columns = useMemo(
    (): TableColumnProps<IAppointment>[] => [
      {
        key: 'title',
        dataIndex: 'title',
        title: locale.labels.visitReason,
        sorter: (a, b) => null,
        onCell: redirectToAppointment,
        render: (title) => title,
        width: '15%'
      },
      {
        key: 'even_start_time',
        title: locale.labels.time,
        sorter: (a, b) => null,
        onCell: redirectToAppointment,
        render: getTime
      },
      {
        key: 'status',
        title: locale.labels.status,
        sorter: (a, b) => null,
        render: (e) => (
          <div
            style={{
              display: 'flex',
              flexDirection: 'row',
              alignItems: 'center'
            }}
          >
            <span style={{ marginRight: 6 }}>{defineStatus(e)}</span>
            <IncompleteHealthRecordMarker appointment={e} locale={locale} />
          </div>
        )
      },
      {
        key: 'patients',
        title: locale.labels.patient,
        render: getPatientView
      },
      {
        key: 'client_phone',
        title: locale.labels.client,
        sorter: (a, b) => null,
        render: (e) => (
          <>
            <Link className={'custom-links'} to={redirectToClient(e)}>
              {ListFieldWithTooltip(e.client?.name, 15)}{' '}
            </Link>
            {!!e.client?.phone_number && (
              <Link
                to={`tel:${e.client?.country_code}${e.client?.phone_number}`}
                className='d-flex align-baseline nowrap'
              >
                (<span className='icofont icofont-ui-cell-phone mr-1' style={{ fontSize: 16 }} />
                {`+${e.client?.country_code}${e.client?.phone_number}`})
              </Link>
            )}
            {blacklistedStatus(e.client)}
          </>
        )
      },
      {
        key: 'doctor_name',
        dataIndex: 'doctor',
        title: locale.labels.doctor,
        sorter: (a, b) => null,
        onCell: redirectToAppointment,
        render: (doctor) => ListFieldWithTooltip(doctor?.name)
      },
      {
        key: 'location',
        title: locale.labels.location,
        sorter: (a, b) => null,
        onCell: redirectToAppointment,
        render: (e) => (e?.location ? ListFieldWithTooltip(e.location?.details) : '-')
      },
      {
        key: 'id',
        title: locale.labels.actions,
        render: (e) => (
          <div className='buttons-list nowrap'>
            <Tooltip
              trigger={'hover'}
              placement='topLeft'
              title={!canStart(e) ? locale.messages.appointmentCanNotBeStartedList : null}
            >
              <Button
                className='change-appointment-status-button'
                disabled={!canStart(e)}
                onClick={() => onStatusChange('in_progress', e)}
                shape='circle'
                type='primary'
              >
                <span className='icofont-play-alt-2' style={{ fontSize: '17px' }} />
              </Button>
            </Tooltip>
            <Tooltip
              trigger={'hover'}
              placement='topLeft'
              title={!canCancel(e) ? locale.messages.appointmentCanNotBeCancelledList : null}
            >
              <Button
                className='change-appointment-status-button'
                disabled={!canCancel(e)}
                onClick={() => {
                  setCurrentAppointmentId(e.id);
                  setShowCancellationModal(true);
                }}
                shape='circle'
                danger
              >
                <span className='icofont-ui-block' style={{ fontSize: '17px' }} />
              </Button>
            </Tooltip>
          </div>
        )
      }
    ],
    [data]
  );

  return (
    <>
      <VLXSearch
        filterResults={filterResults}
        savedFiltersName={'appointmentListFilters'}
        setSearchItemsArray={setSearchItemsArray}
        selectOptions={localizedAppointmentListStatuses}
        selectPlaceholder={locale.labels.filterByStatus}
        enableFilteringByDate
      />
      <Spin spinning={loading}>
        <Table
          pagination={false}
          className='accent-header appointments-list-table'
          rowKey='id'
          onChange={handleChange}
          dataSource={data}
          columns={columns}
          scroll={{ x: 'max-content' }}
        />
        <Row
          style={{
            justifyContent: 'space-between',
            marginTop: 10,
            alignItems: 'center'
          }}
        >
          <Pagination
            defaultCurrent={1}
            showSizeChanger={false}
            showLessItems
            pageSize={20}
            current={+metadata.page}
            total={metadata.total}
            onChange={onPageChange}
          />
          <AddAppointment
            calendarView={false}
            searchItemsArray={searchItemsArray}
            callbackFn={getResults}
          />
        </Row>
        {showCancellationModal && (
          <CancellationForm
            listView={true}
            appointmentId={currentAppointmentId}
            clinicId={clinicId}
            getResults={getResults}
            showCancellationModal={showCancellationModal}
            setShowCancellationModal={setShowCancellationModal}
            currentPage={+metadata.page}
            getUserAppointmentsDataAfterSuccess
            searchArray={searchItemsArray}
          />
        )}
      </Spin>
    </>
  );
};

export default AppointmentsList;
