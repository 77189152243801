import { notification } from 'antd';
import { IDocumentDetails } from 'interfaces/document-details';
import { createInventoryItemForInvoice } from 'services/clinic/inventory/invoices/inventoryItems/inventoryItemsService';
import {
  IndexInventoryItemsResponse,
} from 'services/clinic/inventory/invoices/inventoryItems/models/indexInventoryItemsResponse';
import {
  createClinicDocument,
  createClinicInvoice,
  deleteClinicInvoice,
  getClinicDocumentsList,
  indexClinicInvoices,
  indexClinicInvoiceStockItems,
  removeClinicDocument,
  showClinicInvoice,
  updateClinicInvoice,
} from 'services/clinic/inventory/invoices/invoicesService';
import { removeClinicStockItem, updateClinicStockItem } from 'services/clinic/inventory/stockService';
import { IInvoiceData, IInvoiceDataItem } from 'services/interfaces/clinics/inventory/invoice/IInvoiceData';
import { InvoiceCreateUpdateRequest } from 'services/interfaces/clinics/inventory/invoice/InvoiceCreateUpdateRequest';
import {
  InvoiceStockItemCreateUpdateRequest,
} from 'services/interfaces/clinics/inventory/invoice/InvoiceStockItemCreateUpdateRequest';
import { IStockItemCreateEditRequest } from 'services/interfaces/clinics/inventory/stock/IStockItemCreateEditRequest';
import { ISortObject } from 'services/interfaces/ISortObject';

import {
  InvoicesResetAction,
  MERGE_INVOICE_DOCUMENTS_LIST,
  MergeInvoiceDocumentsListAction,
  RESET,
  SET_BE_ERROR,
  SET_INVOICE,
  SET_INVOICE_DOCUMENTS_LIST,
  SET_LOADING,
  SET_SELECTED_INVOICE,
  SET_SELECTED_INVOICE_STOCK_ITEMS,
  SetBeError,
  SetInvoiceAction,
  SetLoadingAction,
  SetSelectedInvoiceAction,
  SetSelectedInvoiceDocumentsListAction,
  SetSelectedInvoiceStockItemsAction,
  SHOW_CREATE_MODAL,
  ShowCreateModal,
} from './types';

export const setInvoice = (data: IInvoiceData): SetInvoiceAction => ({
  type: SET_INVOICE,
  payload: data
});

export const resetInvoices = (): InvoicesResetAction => ({
  type: RESET
});

export const setSelectedInvoice = (data: IInvoiceDataItem): SetSelectedInvoiceAction => ({
  type: SET_SELECTED_INVOICE,
  payload: data
});

export const setSelectedInvoiceStockItems = (
  data: IndexInventoryItemsResponse
): SetSelectedInvoiceStockItemsAction => ({
  type: SET_SELECTED_INVOICE_STOCK_ITEMS,
  payload: data
});

export const setLoading = (value: boolean): SetLoadingAction => ({
  type: SET_LOADING,
  payload: value
});

export const setBackendError = (value: boolean): SetBeError => ({
  type: SET_BE_ERROR,
  payload: value
});

export const showCreateModal = (value: boolean): ShowCreateModal => ({
  type: SHOW_CREATE_MODAL,
  payload: value
});

export const setInvoiceDocumentsList = (data: any): SetSelectedInvoiceDocumentsListAction => ({
  type: SET_INVOICE_DOCUMENTS_LIST,
  payload: data
});

export const mergeInvoiceDocumentsList = (data: any): MergeInvoiceDocumentsListAction => ({
  type: MERGE_INVOICE_DOCUMENTS_LIST,
  payload: data
});

export const indexInvoices = (
  clinicId: number,
  page: number,
  searchArray?: any[],
  sortObject?: ISortObject,
  itemsQuantity?: number
): any => {
  return (dispatch) => {
    dispatch(setLoading(true));
    indexClinicInvoices(clinicId, page, searchArray, itemsQuantity, sortObject)
      .then((data) => {
        dispatch(setInvoice(data));
      })
      .catch((err) => {
        console.log(err);
      })
      .finally(() => dispatch(setLoading(false)));
  };
};

export const getInvoiceById = (clinicId: number, invoiceId: number): any => {
  return (dispatch) => {
    dispatch(setLoading(true));
    showClinicInvoice(clinicId, invoiceId)
      .then((data) => {
        dispatch(setSelectedInvoice(data));
      })
      .catch((err) => {
        console.log(err);
      })
      .finally(() => dispatch(setLoading(false)));
  };
};

export const removeStockItem = (
  clinicId: number,
  stockId: number,
  itemId: number,
  invoiceId: number,
  locale
): any => {
  return (dispatch) => {
    dispatch(setLoading(true));
    removeClinicStockItem(clinicId, stockId, itemId)
      .then((data) => {
        notification.success({ message: locale.messages.stockItemRemovedOk });
        dispatch(getInvoiceById(clinicId, invoiceId));
        dispatch(setSelectedInvoiceStockItems(null));
        dispatch(getInvoiceStockItemsById(clinicId, invoiceId));
      })
      .catch((err) => {
        dispatch(setBackendError(true));
        console.log(err);
      })
      .finally(() => dispatch(setLoading(false)));
  };
};

export const updateStockItem = (
  clinicId: number,
  stockId: number,
  data: IStockItemCreateEditRequest,
  itemId: number,
  invoiceId: number
): any => {
  return (dispatch) => {
    dispatch(setLoading(true));
    updateClinicStockItem(clinicId, stockId, data, itemId)
      .then(() => {
        dispatch(getInvoiceById(clinicId, invoiceId));
        dispatch(getInvoiceStockItemsById(clinicId, invoiceId));
      })
      .catch((err) => {
        dispatch(setBackendError(true));
        console.log(err);
      })
      .finally(() => dispatch(setLoading(false)));
  };
};

export const getInvoiceStockItemsById = (clinicId: number, invoiceId: number, page?: number): any => {
  return (dispatch) => {
    indexClinicInvoiceStockItems(clinicId, invoiceId, page)
      .then((data) => {
        dispatch(setSelectedInvoiceStockItems(data));
      })
      .catch((err) => {
        console.log(err);
      });
  };
};

export const createInvoice = (
  clinicId: number,
  data: InvoiceCreateUpdateRequest,
  callbackFn: (id) => void
): any => {
  return (dispatch) => {
    dispatch(setLoading(true));
    createClinicInvoice(clinicId, data)
      .then((data) => {
        dispatch(indexInvoices(clinicId, 1));
        dispatch(showCreateModal(false));
        callbackFn(data.id);
      })
      .catch((err) => {
        dispatch(setBackendError(true));
        console.log(err);
      })
      .finally(() => dispatch(setLoading(false)));
  };
};

export const createInvoiceStockItem = (
  clinicId: number,
  invoiceId: number,
  stockId: number,
  data: InvoiceStockItemCreateUpdateRequest
): any => {
  return (dispatch) => {
    dispatch(setLoading(true));
    createInventoryItemForInvoice(clinicId, invoiceId, stockId, data)
      .then((data) => {
        dispatch(indexInvoices(clinicId, 0));
        dispatch(showCreateModal(false));
        dispatch(getInvoiceById(clinicId, invoiceId));
        dispatch(getInvoiceStockItemsById(clinicId, invoiceId));
      })
      .catch((err) => {
        dispatch(setBackendError(true));
        console.log(err);
      })
      .finally(() => dispatch(setLoading(false)));
  };
};

export const updateInvoice = (
  clinicId: number,
  deviceId: number,
  data: InvoiceCreateUpdateRequest
): any => {
  return (dispatch) => {
    dispatch(setLoading(true));
    updateClinicInvoice(clinicId, deviceId, data)
      .then((data) => {
        dispatch(getInvoiceById(clinicId, deviceId));
      })
      .catch((err) => {
        dispatch(setBackendError(true));
        console.log(err);
      })
      .finally(() => dispatch(setLoading(false)));
  };
};

export const removeInvoice = (clinicId: number, invoiceId: number, locale): any => {
  return (dispatch) => {
    dispatch(setLoading(true));
    deleteClinicInvoice(clinicId, invoiceId)
      .then((data) => {
        notification.success({ message: locale.messages.invoiceRemovedOk });
        dispatch(setSelectedInvoice(null));
        dispatch(indexInvoices(clinicId, 0));
      })
      .catch((err) => {
        dispatch(setBackendError(true));
        console.log(err);
      })
      .finally(() => dispatch(setLoading(false)));
  };
};

export const getDocumentsList = (clinicId: number, invoiceId: number): any => {
  return (dispatch) => {
    dispatch(setInvoiceDocumentsList(null));
    getClinicDocumentsList(clinicId, invoiceId)
      .then((data) => {
        dispatch(setInvoiceDocumentsList(data.reverse()));
      })
      .catch((e) => console.log(e));
  };
};

export const createPatientDocument = (
  clinicId: number,
  invoiceId: number,
  data: IDocumentDetails
): any => {
  return (dispatch) => {
    createClinicDocument(clinicId, invoiceId, data)
      .then((res) => {
        dispatch(getDocumentsList(clinicId, invoiceId));
      })
      .catch((e) => console.log(e));
  };
};

export const removeInvoiceDocument = (
  clinicId: number,
  invoiceId: number,
  documentId: number
): any => {
  return (dispatch) => {
    removeClinicDocument(clinicId, invoiceId, documentId)
      .then((res) => {
        dispatch(getDocumentsList(clinicId, invoiceId));
      })
      .catch((e) => console.log(e));
  };
};
