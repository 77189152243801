import './scss/PrintPricesForm.scss';

import { Row } from 'antd';
import { money } from 'helpers/list-helpers';
import { useLocale } from 'hooks/useLocale';
import { ILocale } from 'interfaces/locale';
import { useGetResidual } from 'pages/inventory/stock/utils/useGetResidual';
import React from 'react';
import Barcode from 'react-barcode';
import { ISelectedStockPrices } from 'services/interfaces/clinics/inventory/stock/ISelectedStock';
import { IStockItem } from 'services/interfaces/clinics/inventory/stock/IStockItem';

import { IPriceComponent } from './types';

type Props = React.PropsWithChildren<{
  item: IPriceComponent;
  printConfig: Array<string>;
  locale: ILocale;
  price: ISelectedStockPrices;
  stock: IStockItem;
}>;

export const PricesComponent = ({
  item,
  printConfig,
  locale,
  price,
  stock
}: Props): JSX.Element => {
  const currencies = useLocale('private.currencies').labels;

  const { unitOfMeasurement, packagingFormat } =
    useGetResidual(stock);

  const printName = printConfig.some(el => el === 'name');
  const printBarcode = printConfig.some(el => el === 'barcode');
  const printPrice = printConfig.some(el => el === 'price');
  const printArticle = printConfig.some(el => el === 'sku');

  return (
    <div className='price-block'>
      {printName && <div className='price-block__top-cont'>
        <div className='price-block__title'>{stock?.name}</div>
        <div className='price-block__manufacturer'>{stock?.manufacturer}</div>
      </div>}
      <Row className='price-block__row'>
        <div className='price-block__barcode'>
          {printBarcode && (stock?.barcode || stock?.sku) && <Barcode value={stock?.barcode ?? stock?.sku} />}
        </div>
        <div className='price-block__price-column'>
          {printPrice && <>
            {item.print !== 'piece' && <div className='price-block__price-row'>
              <span className='price-block__price-label'>{packagingFormat}:</span>
              <div className='price-block__price-row__price'>
                <span className='price-block__price-value'>{money(price?.price_per_package)}</span>
                <span className='price-block__price-currencies'>{currencies.uah}</span>
              </div>
            </div>}
            {printPrice && item.print !== 'package' && <div className='price-block__price-row'>
              <span className='price-block__price-label'>{unitOfMeasurement}:</span>
              <div className='price-block__price-row__price'>
                <span className='price-block__price-value'>{money(price?.price_per_unit)}</span>
                <span className='price-block__price-currencies'>{currencies.uah}</span>
              </div>
            </div>}
          </>}
        </div>
      </Row>
      {printArticle && stock?.sku && <div className='price-block__article'>{locale.labels.article}: {stock?.sku}</div>}
    </div>
  );
};
