const breeds = [
  {
    value: 'rabbit',
    enGB: 'Rabbit',
    ukUA: 'Кролик'
  },
  {
    value: 'rat',
    enGB: 'Rat',
    ukUA: 'Щур'
  },
  {
    value: 'hamster',
    enGB: 'Hamster',
    ukUA: 'Хом\'як'
  },
  {
    value: 'chinchilla',
    enGB: 'Сhinchilla',
    ukUA: 'Шиншила'
  },
  {
    value: 'guinea_pig',
    enGB: 'Guinea pig',
    ukUA: 'Морська Свинка'
  },
  {
    value: 'mouse',
    enGB: 'mouse',
    ukUA: 'Миша'
  },
  {
    value: 'other',
    enGB: 'Other',
    ukUA: 'Інший'
  }
];

export default breeds;
